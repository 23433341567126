import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/Forms';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
@Injectable({
    providedIn: 'root'
  })

export class EmpRegValidate {

  constructor(private http: HttpserviceService) { }

  getEmployeeconfig(params: any){
    const query = `cid=${params.cid}&flag=${params.flag}&EmpID=${params.empid}&LedgerID=${params.ledgerid}`;
    return this.http.httpGet('/EmployeeRegistration/GetEmployeeRegistration?' , query);
    
  }

  setprojectconfig(data: any){
    return this.http.httpPost( '/EmployeeRegistration/SetEmployeeRegistration', data)
    //.catch(this.handleError);
  }

  form: FormGroup = new FormGroup({
    CID: new FormControl(''),
    EmployeeID: new FormControl(''),
    FirstName: new FormControl('', [Validators.required]),
    LastName: new FormControl(''),
    AliasName:new FormControl('', Validators.required),
    FamilyName: new FormControl(''),
    DOB: new FormControl(''),
    Gender: new FormControl('', Validators.required),
    MaritalStatus:new FormControl(''),
    BloodGrp:new FormControl(''),
    Nationality:new FormControl(''),

    Designation: new FormControl(''),
    Department: new FormControl(''),
    Grade: new FormControl(''),
    Status: new FormControl(''),
    TimeZone: new FormControl(''),
    DateofJoining: new FormControl(''),
    DateofRelieving: new FormControl(''),
    CreateLogin:new FormControl(''),
    AuthorizationGroup: new FormControl('', Validators.required),
    PreFlatorStreet: new FormControl(''),
    PreArea: new FormControl(''),
    PreCity: new FormControl(''),
    PreState: new FormControl(''),
    PrePincode: new FormControl(''),
    PreCountry: new FormControl(''),
    PreTelephone: new FormControl(''),
    PreExtension: new FormControl(''),
    PreMobile1: new FormControl(''),
    PreMobile2: new FormControl(''),
    PreEmail1: new FormControl('', [Validators.required]),
    PreEmail2: new FormControl(''),
    PerFlatorStreet: new FormControl(''),
    PerArea: new FormControl(''),
    PerCity: new FormControl(''),
    PerState: new FormControl(''),
    PerPincode: new FormControl(''),
    PerCountry: new FormControl(''),
    PerTelephone: new FormControl(''),
    PerExtension: new FormControl(''),
    BankACName: new FormControl(''),
    BankACNo: new FormControl(''),
    IBAN: new FormControl(''),
    BankACType: new FormControl(''),
    BankName: new FormControl(''),
    BankBranchName: new FormControl(''),
    TownCity: new FormControl(''),    
    Category: new FormControl(''),
    SubCategory: new FormControl(''),
   // GroupName: new FormControl(''),
 

  });

  initializeFormGroup(){
    this.form.setValue({
      CID: '',
      EmployeeID: '',
      FirstName: '',
      LastName: '',
      AliasName:'',
      FamilyName: '',
      DOB: '',
      Gender: '',
      MaritalStatus:'',
      BloodGrp:'',
      Nationality:'',
  
      Designation: '',
      Department: '',
      Grade: '',
      Status: '',
      TimeZone: '',
      DateofJoining: '',
      DateofRelieving: '',
      CreateLogin:'',
      AuthorizationGroup: '',
      PreFlatorStreet: '',
      PreArea: '',
      PreCity: '',
      PreState: '',
      PrePincode: '',
      PreCountry: '',
      PreTelephone: '',
      PreExtension: '',
      PreMobile1: '',
      PreMobile2: '',
      PreEmail1: '',
      PreEmail2: '',
      PerFlatorStreet: '',
      PerArea: '',
      PerCity: '',
      PerState: '',
      PerPincode: '',
      PerCountry: '',
      PerTelephone: '',
      PerExtension: '',
      BankACName: '',
      BankACNo: '',
      IBAN: '',
      BankACType: '',
      BankName: '',
      BankBranchName: '',
      TownCity: '',    
      Category: '',
      SubCategory: '',
     // GroupName:''
    });
  }

  populateForm(data){
   
    console.log('dataEdit',data);
    const Employee = {
      CID: data[0].CID,
      EmployeeID: data[0].EmpID,
      FirstName: data[0].FirstName,
      LastName: data[0].LastName,
      AliasName:data[0].Name,
      FamilyName: data[0].FamilyName,
      DOB: data[0].DOB,
      Gender: data[0].Gender,
      MaritalStatus:data[0].MaritalStatus,
      BloodGrp:data[0].BloodGroup,
      Nationality:data[0].Nationality,
      CreateLogin:false,
      Designation: data[0].Designation,
      Department: data[0].Department,
      Grade: data[0].Gname,
      Status: data[0].Status,
      TimeZone: data[0].TimeZoneID,
      DateofJoining: data[0].joindate,
      DateofRelieving: data[0].RelievedDate,
      AuthorizationGroup: data[0].AuthGroupID,
      PreFlatorStreet: data[0].PreStreet,
      PreArea:data[0].PreArea,
      PreCity: data[0].PreTown,
      PreState: data[0].PreState,
      PrePincode: data[0].PrePincode,
      PreCountry: data[0].PreCountry,
      PreTelephone: data[0].PreTel,
      PreExtension: data[0].PreExtension,
      PreMobile1: data[0].PreMobile1,
      PreMobile2: data[0].PreMobile2,
      PreEmail1: data[0].PreEmail1,
      PreEmail2: data[0].PreEmail2,
      PerFlatorStreet: data[0].PerStreet,
      PerArea: data[0].PerArea,
      PerCity: data[0].PerTown,
      PerState: data[0].PerState,
      PerPincode: data[0].PerPincode,
      PerCountry: data[0].PerCountry,
      PerTelephone: data[0].PerTel,
      PerExtension: data[0].PerExtension,
      BankACName: data[0].BankACHolderName,
      BankACNo: data[0].BankACNo,
      IBAN: data[0].IBAN,
      BankACType: data[0].BankACType,
      BankName: data[0].BankName,
      BankBranchName: data[0].Branch,
      TownCity:data[0].Town,    
      Category: data[0].category,
      SubCategory: data[0].subcategory,      
    };
    this.form.setValue(Employee);

  }
 
}

