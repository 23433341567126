import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MerchantComponent } from '../merchant/merchant.component';
import { GenericService } from './../../../../Services/OtherServices/generic.service';

@Component({
  selector: 'app-merchantlist',
  templateUrl: './merchantlist.component.html',
  styleUrls: ['./merchantlist.component.scss'],
})
export class MerchantlistComponent implements OnInit {
 
  searchKey: string;

  CID:any;
  flag:any;
  Condition:any;
  companies:any;
  datasource:any;
  Clientlist:any;
  Product:any;
  Priority:any;

  displayedColumns =['MerchantID','MerchantName','Type','Status','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private dialog: MatDialog,public service: HttpserviceService,private generic: GenericService,
    public notificationService: NotificationService,private dialogService: DialogService
    )
    {
     
    }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  Refresh()
  {

  }
  addRow()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: 'ADD',
      pid:0,
      client:this.Clientlist,
      product:this.Product,
      priority:this.Priority,
      data:"",
      // Rolelist:this.Rolelist,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(MerchantComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.CID=this.CID;
      this.flag='PAGELOAD'
      this.Loaddetails();

      }); 
  }
  Edit(event)
  {
   const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: 'EDIT',
      pid:0,
      client:this.Clientlist,
      product:this.Product,
      priority:this.Priority,
      data:event,
      // Rolelist:this.Rolelist,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(MerchantComponent, dialogConfig)
    dialogRef.afterClosed() .subscribe(result => {
      this.CID=this.CID;
      this.flag='PAGELOAD'
      this.Loaddetails();

      });
  }
  Delete(event)
  {
    this.dialogService.openConfirmDialog('Are you sure to delete this Client?')
      .afterClosed().subscribe(res => {
       if (res) {
  let params = { 
    cid:this.CID,
    flag:'DELETE', 
    MerchantId:event.MerchantID,
    Name:event.MerchantName,
    Since:event.MerchantSince,
    Status:1,
    Address1:event.Address1,
    Address2:event.Address2,
    Pincode:event.PoBox,
    TimeZone:event.CusTimeZone,
    ContName:event.Contact,
    Email:event.Email,
    Mobile:event.Mobile,
    ConTel:event.Tel,
    SupportID:event.SupportMailID,
    LedgerID:0,
    GroupID:0,
    dtClientSubStatus:0,
    dtExample:null
    
   };

  this.service.httpPost('/Client/SetClient',params)
  .subscribe(
    (data) => {
      let result: any;
      result = data;       
      if (result.errno === 1) {
        this.notificationService.success(result.errdesc);
        this.flag='PAGELOAD'
        this.Loaddetails();
      }
      else {
        this.notificationService.success(result.errdesc); //':: Problem details');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });
  }
});

  }
  Loaddetails()
  {
    let params = {
      CID:this.CID,
      Flag:this.flag,
      MerchantId:'0',
      Name:'""'
  
       }
          // const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
          this.service.httpPost('/Client/GetClient',params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.Clientlist=result.respdata.Clientsub;
                this.Product=result.respdata.Product;
                this.Priority=result.respdata.Priority;
                // this.datasource=result.respdata.Merchantlist;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Merchantlist);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
              }
              else
              {
                this.datasource=result.respdata.EntityList;
              }
                
                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  exportAsExcel()
  {
    this.generic.excelExport(this.datasource.data, 'MerchantList', 'MerchantList' );
  }

}

export interface GroupALL {
  MerchantID: string;
  MerchantName: string;
  Type : string;
  

}
