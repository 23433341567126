import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../../../../app/Modules/user';
import { AppError } from '../../../../../app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from '../../../../../app/Services/httpServices/ErrorHandler/bad-input';
import { NotificationService } from '../../../../../app/Services/OtherServices/notification.service';
import { EmpRegValidate } from '../emp-reg-Validate';
// import { EmpRegService } from './../../EmployeeRegistration/emp-reg.service';
// import { NotificationService } from './../../../../shared/notification.service';
// import { DatePipe, formatDate } from '@angular/common';
// import { AppError } from '../../../common/app-error';
// import { BadInput } from '../../../common/bad-input';
// import { User } from '../../../../models';
import { UploadprofilepicComponent } from '../../../../components/HR/EmployeeRegistration/uploadprofilepic/uploadprofilepic.component';

@Component({
  selector: 'app-emp-reg',
  templateUrl: './emp-reg.component.html',
  styleUrls: ['./emp-reg.component.css']
})
export class EmpRegComponent implements OnInit {

  flag: string;
  forceid:number=0;
  required:boolean=false;
  readonly:boolean=false;
  ledgerID:number;
  Empid:string;
  username:string;
  dataSource: any;
  dataSource1: any;
  model: any;
  cid:any;
  isEditable = true;
  selectedValue: string;
  optionGender = [
    { id : 1, GenderName: 'Male'},
    { id : 0, GenderName: 'Female'}
  ];
  optionMaritalStatus = [
    { id : 1, Name: 'Married'},
    { id : 0, Name: 'Single'}
  ];
  optionEmployeeStatus = [
    { id : 0, Name: 'Active'},
    { id : 1, Name: 'InActive'}
  ];
  
  //optionGender: string[] = ['Male', 'Female', 'Other'];
  //optionMaritalStatus: string[] = ['Married', 'Single'];
 // optionEmployeeStatus: string[] = ['Active', 'InActive'];
  //optionNationality: string[] = ['India', 'UAE','SaudiArabiya','USA'];
  optionBloodGroup: string[] = ['A+', 'A-','B+','B-','O+','O-','AB+','AB-'];
  optionNationality:any;
  companies: any;//string[] = ['ASPEN', 'BINHANIF'];
  groupid:any;
  department:any;
  designation:any;
  grade:any;
  timezone:any;
  authorizationgroup:any;
  dashgroupid:any;
  SubCategory:any;
  Categories:any;
  loginvisible:boolean=false;
  date= new Date();

  constructor(public service:EmpRegValidate,
    public dialogRef: MatDialogRef<EmpRegComponent>,
    private datePipe: DatePipe, private dialog: MatDialog,
    public notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.flag = this.data.flag;
      if(this.flag=='ADD')
         this.loginvisible=true;
      else
         this.loginvisible=false;   

      this.Empid = this.data.empid;
      this.ledgerID=this.data.ledgerID;
    // console.log('CID',this.data);
      //console.log('this.ledgerID',this.Empid ,'  ,', this.flag );
     let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
     this.username=curUser.username;


     }

  ngOnInit(): void {
    this.cid=this.data.cid; 
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   //this.forceid=curUser.configparam.find(e => e.tag === 'useEmployeeID')['value'];
   this.forceid=curUser.CustomerSettings.useEmployeeID;
   this.required=this.forceid==1? true: false;
   this.readonly=this.forceid==1? false: true;
    // console.log("curUser", curUser.configparam.find(e => e.tag === 'useEmployeeID')['value'])
    this.getPageLoad();
    this.service.form.controls.CID.setValue(this.cid);
    if(this.flag=='ADD')
       this.service.form.controls.Status.setValue(0);
  }
  
  changesite(e) {
   // console.log('change',e.value);
    this.cid=e.value;
    this.getPageLoad();
  }
  
  onCloseDialog()
  {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close({event: 'CLEAR'});
  }
  onClose(model){
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close({event: this.flag, data: model});
  }
  onClear()
  {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.service.form.controls.fcCompany.setValue(this.cid);
  }


  getPageLoad()
  {
    let params = {
      cid: this.cid,
      flag:this.flag ,
      ledgerid:"0",
      empid:this.Empid     
        }
        this.service.getEmployeeconfig(params)
        .subscribe(data => {
          let result: any;
          result=data;
          if (result.errno === 0){
            //this.companies = result.respdata.Table2;            
            this.companies = result.respdata.Table;
            this.groupid=result.respdata.Table2;
            this.department=result.respdata.Table2;
            this.designation = result.respdata.Table3;            
            this.timezone=result.respdata.Table4;
            this.grade=result.respdata.Table5;            
            this.authorizationgroup=result.respdata.Table6;
            this.dashgroupid=result.respdata.Table10;
            this.groupid=result.respdata.Table9;
            this.SubCategory=result.respdata.Table8;
            this.Categories=result.respdata.Table7;
            this.optionNationality=result.respdata.Table11;
           //  console.log('this.companies',result.respdata.Table11);
            // console.log('this.designation',this.optionNationality);
            if (this.flag == 'EDIT')
            {
              const params = Object.assign(result.respdata.Table12);
            //  console.log("params",result.respdata.Table12);
              this.service.populateForm(params);
            }
           
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
  }

  UploadImage()
  {
    //console.log('HI');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid: this.data.cid
      // Flag:'ADD' , 
      // Employees:this.Employees,                           
      // Type:this.Types,
      // Currency:this.Currency,
      // RequestID:''   
    }
    const dialogRef = this.dialog.open(UploadprofilepicComponent,dialogConfig)
    dialogRef.afterClosed()
  }

  getEditPageLoad()
  {
    let params = {
      cid: this.cid,
      flag:"EditGetData",
      ledgerid:this.ledgerID,
      empid:"0"     
        }
        this.service.getEmployeeconfig(params)
        .subscribe(data => {
          let result: any;
          result=data;
          if (result.errno === 0){
            //this.companies = result.respdata.Table2;            
            this.companies = result.respdata.Table;
            this.groupid=result.respdata.Table2;
            this.department=result.respdata.Table2;
            this.designation = result.respdata.Table3;            
            this.timezone=result.respdata.Table4;
            this.grade=result.respdata.Table5;            
            this.authorizationgroup=result.respdata.Table6;
            this.dashgroupid=result.respdata.Table10;
            this.groupid=result.respdata.Table9;
            this.SubCategory=result.respdata.Table8;
            this.Categories=result.respdata.Table7;
            
            // console.log('this.companies',this.SubCategory);
            // console.log('this.designation',this.Categories);
           
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
  }

  onSubmit()
  {
    if (this.service.form.valid){
      const curDate = new Date();
      const paramsFormValue = this.service.form.value;

      // var myObj=this.dataSource1

      // delete this.dataSource1.DaysHours;
      // delete this.dataSource1.__KEY__;

      // delete myObj.DaysHours;
      // delete myObj.__KEY__;
      // console.log('list', this.dataSource1.length);
      // if ( this.dataSource1.length == 0)
      // {
      //   this.dataSource1 = [{Role: '', hours: ''}];
      // console.log('list123', this.dataSource1);
      // }
      
      paramsFormValue.DOB = this.datePipe.transform(paramsFormValue.DOB, 'dd-MM-yyyy');
      paramsFormValue.DateofJoining = this.datePipe.transform(paramsFormValue.DateofJoining, 'dd-MM-yyyy');
      paramsFormValue.DateofRelieving = this.datePipe.transform(paramsFormValue.DateofRelieving, 'dd-MM-yyyy');

      paramsFormValue.curDate = this.datePipe.transform(curDate, 'dd-MM-yyyy');
      paramsFormValue.Category = paramsFormValue.Category.toString();
      paramsFormValue.TimeZone = paramsFormValue.TimeZone.toString();
      paramsFormValue.Grade = paramsFormValue.Grade.toString();
      paramsFormValue.AuthorizationGroup = paramsFormValue.AuthorizationGroup.toString();
      paramsFormValue.CreateLogin=this.service.form.controls.CreateLogin.value==true?1:0;
      // paramsFormValue.BankACNo = paramsFormValue.BankACNo==null? "":paramsFormValue.BankACNo;
      // paramsFormValue.BankName = paramsFormValue.BankName==null? "":paramsFormValue.BankName;
      // paramsFormValue.IBAN = paramsFormValue.IBAN==null? "":paramsFormValue.IBAN;
    //  console.log('loginvalue',this.service.form.controls.CreateLogin.value)
     //  console.log("sss",paramsFormValue );
      const  paramsExtra = {
        //CID: 101,
        Flag: this.flag,
        LedgerID: this.ledgerID==undefined?0:this.ledgerID,
        //UserID: '',
        intChkForceId:this.forceid,
       
        //CurDate:curDate,
        CostPerHour:0,        
        FingerPrintID:'',
        UserName:this.username,
        Image:""
        //GroupID: sessionStorage.getItem('groupid'),
        // listarray: this.dataSource1
        // Year:this.requestyear,
        // Type:this.type


        // Category: string
        // Grade string
        // MaritalStatus: int
        // TimeZone string
      };
      const params = Object.assign(paramsFormValue, paramsExtra);
      console.log("save",params);
      this.service.setprojectconfig(params)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
          // console.log('create:', users.errno);

          if (users.errno === 0){
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.notificationService.success('Submitted successfully');
            this.onClose(this.model);

          }
          else{
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
    }
  }

}
