import { DatePipe } from '@angular/common';
import { User } from './../../../../Modules/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';


interface Level {
  id: number;
  name: string;
}

@Component({
  selector: 'app-productconfig',
  templateUrl: './productconfig.component.html',
  styleUrls: ['./productconfig.component.scss'],
})
export class ProductconfigComponent implements OnInit {

  ProductFormGroup: FormGroup;
  ProductID:any;
  cid:any;
  Flag:any;
  ProductName:any;
  Description:any;
  Status:any;
  RegisterSince:any
  CurLedgerID:any;

  Statuslist: Level[] = [
    {id: 0, name: 'Active'},
    {id: 1, name: 'Locked'}
    
  ];
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<ProductconfigComponent>,private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any)
    { 
      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.ProductID = this.data.ProductID;
      this.ProductName=this.data.ProductName;
      this.Status=this.data.Status;
      this.Description=this.data.Description;
      this.RegisterSince=this.data.RegisterSince;

      
      this.ProductFormGroup = this.formBuilder.group({  
        fcProductName:['', Validators.required],     
        fcDescription:[''],
        fcStatus:['', Validators.required], 
        fcSince:['', Validators.required]   
      });  

      this.ProductFormGroup.controls.fcProductName.setValue(this.ProductName);
      this.ProductFormGroup.controls.fcDescription.setValue(this.Description);
      this.ProductFormGroup.controls.fcStatus.setValue(this.Status);
       this.ProductFormGroup.controls.fcSince.setValue(this.RegisterSince);
    }

  ngOnInit() 
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
  Submit()
  {
    let params = { 
      CID:this.cid,
      Flag: this.Flag, 
      ID: this.ProductID, 
      Name:  this.ProductFormGroup.get('fcProductName').value,   
      Description: this.ProductFormGroup.get('fcDescription').value,
      Status:this.ProductFormGroup.get('fcStatus').value,
      Register:this.datePipe.transform(this.ProductFormGroup.get('fcSince').value,'dd-MM-yyyy'),
      UserID: this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')
      
     };
    this.service.httpPost('/ProductConfig/SetProductConfig',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
  onSearchClear()
  {
    this.ProductFormGroup.reset();        
    this.ProductFormGroup.controls.fcStatus.setValue(this.Status);
  }

}
