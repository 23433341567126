
import { Component, OnInit,ViewChild, } from '@angular/core';
import {  FormGroup,FormControl,  FormBuilder,} from '@angular/forms';
import { MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DocumentcontrolValidate } from '../documentcontrolvalidate';
import { NotificationService } from '../../../../../app/Services/OtherServices/notification.service';
import { User } from '../../../../../app/Modules/user';
import { AppError } from '../../../../../app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from '../../../../../app/Services/httpServices/ErrorHandler/bad-input';

export interface User2 {
  Name: string;
}
@Component({
  selector: 'app-documentcontrol',
  templateUrl: './documentcontrol.component.html',
  styleUrls: ['./documentcontrol.component.css']
})
export class DocumentcontrolComponent implements OnInit {

  employeelist:any;
  Categorylist:any;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  empid:string[]=[];
  LedgerID:number;
  value: any[] = [];
  flag: string;
  flag1: string;
  model: any;
  Name:any;
  TaskID:number=0;
  Statuslist:any;
  extension:any;
  isvisible:any;
  AliasName:any;
  Comment:any;
  RESUME:any;
  CID:any;
  fieldvisible:boolean=false;
  
        Status= [
            { name: 'Open' },
            { name: 'Hold' },
            { name: 'Completed' },
            { name: 'UnExpired' }
            
        ]

 // displayedColumns =['TaskID','Name','TaskCategory','Desc1','StartDate','DueDate','NotifyFlag','PopUpDays','Status','Action'];
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('form') form: NgForm;



  @ViewChild('fileUpload', { static: false }) fileUpload;
  files  = [];
  retrievedImage: any;
  retrieveResonse:any;
  base64Data:any;


  constructor(private datePipe: DatePipe,
    public DcValidate: DocumentcontrolValidate,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<DocumentcontrolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
  { 

    //console.log('dialogdata',this.data)
    this.flag = this.data.flag;
    this.Name=this.data.Name;
    this.TaskID=this.data.TaskID;
    this.LedgerID=this.data.LedgerID;
    this.CID=this.data.cid;
  
  }
   getPath() {
    var Form = document.forms['frm_upload'];
    var inputName = Form.elements['file1'].value;
    
    var imgPath = inputName;
    Form.elements['file_src'].value = imgPath;
    }
  ngOnInit(): void {
    if(this.data.FormType==='HR')
      this.fieldvisible=true;
    else
       this.fieldvisible=false; 

    this.isvisible = this.TaskID !== 0 ? true : false;
  this.Statuslist=this.Status;
   this.getdocumentdetails();
  this.myControl.setValue({Name: this.Name });  
  this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | User2>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) : this.empid.slice())
      );
      
  }
  selectedproduct(event) {    
    this.LedgerID=event.option.value; 
    this.myControl.setValue({Name: event.option.value });    
    this.LedgerID=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));     
  }
  private _filter(value: string): string[] {

    const filterValue = value.toLowerCase();   
      return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
  }
  displayFn(empid?: User2): string | undefined {
    return empid ? empid.Name : undefined;
  }
    
  updateClick() {
    this.flag1='SAVEFILE';
  }
upload(event: any) {

  var Form = document.forms['frm_upload'];
  
  var inputName = Form.elements['file1'].value;
  
  var imgPath = inputName;
  Form.elements['file_src'].value = imgPath;

  if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
          var localUrl = event.target.result;
  
      }
      reader.readAsDataURL(event.target.files[0]);
  }
  
}
onevent (str) {
  var bytes = [];
  for(var i = 0; i < str.length; i++) {
      var char = str.charCodeAt(i);
      bytes.push(char >>> 8);
      bytes.push(char & 0xFF);
  }
  return bytes;
 
}
onCloseDialog()
  {
    this.DcValidate.form.reset();
    this.DcValidate.initializeFormGroup();
    this.dialogRef.close({event: 'CLEAR'});
  }
  onClose(model){
    this.DcValidate.form.reset();
    this.DcValidate.initializeFormGroup();
    this.dialogRef.close({event: this.flag, data: model});
  }
  onClear()
  {
    this.DcValidate.form.reset();
    this.DcValidate.initializeFormGroup();
  }
  onSubmit()
  {
    if (this.DcValidate.form.valid){
      const curDate = new Date();
      const paramsFormValue = this.DcValidate.form.value;

      paramsFormValue.startdate = this.datePipe.transform(paramsFormValue.startdate, 'yyyy-MM-dd');
      paramsFormValue.enddate = this.datePipe.transform(paramsFormValue.enddate, 'yyyy-MM-dd');
      paramsFormValue.postingdate = this.datePipe.transform(paramsFormValue.postingdate, 'yyyy-MM-dd');
      
      var fileName ='C:/Users/dell/Desktop/images.jpg';
      var flag2
      if (this.flag1=='SAVEFILE')
      {
        flag2=this.flag1
        const fileUpload = this.fileUpload.nativeElement;fileUpload.onchange = () => {  
          let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
          
          let formData = new FormData();      
          formData.append('FileUploadData', this.fileUpload.nativeElement.files[0]); 
          formData.append('TaskID',this.TaskID.toString());
          formData.append('CID',this.CID);   
          formData.append('Flag','SAVEFILE');     
          formData.append('UserID',curUser.username);
          this.DcValidate.AddFileDetails(formData).subscribe(result => {
            let users: any
            users=result;
            if (users.errno === 0){
              this.notificationService.success('Upload successfully');
            }
          });  
          };  
          fileUpload.click();  
      }
      else 
      {
        flag2=this.flag
      const  paramsExtra = {
        cid: this.CID,
        flag: flag2,
        Slno: '0',
        TaskID:this.TaskID,
        LedgerID: this.LedgerID,
        UserName: 'admin',
        Comments:'',
        FileName:'',
        FilePath:'',
        Extension:this.extension,
        AliasName:'',
        EmpName:fileName
      };
      const params = Object.assign(paramsFormValue, paramsExtra);
      this.DcValidate.setdocumentcontrol(params)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
        

          if (users.errno === 0){
            this.notificationService.success('Submitted successfully');
            this.TaskID=users.respdata;
            this.isvisible = this.TaskID !== 0 ? true : false;
            this.flag1='';
          }
          else{
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
          else throw error;
        });
      }
      

      
    }
  }
  getdocumentdetails()
  {
       let params = {
       cid:this.CID,
       flag:'PAGELOAD',
       taskno:this.TaskID
        }
           this.DcValidate.getdocumentcontrol(params)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){
              this.employeelist= result.respdata.Table;
               this.Categorylist= result.respdata.Table1;

               this.empid= _.pluck(this.employeelist, 'Name'); 
      
             }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }



    onClick() {  
      this.flag1='SAVEFILE';
      
  }

    
  }

export interface GroupALL {
  Name: string;
}

