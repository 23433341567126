import { HttpClient } from '@angular/common/http';
import { MatStepper } from '@angular/material/stepper';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { User } from 'src/app/Modules/user';
import { FormGroup,FormControl,Validators, RequiredValidator } from '@angular/forms';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck, filter } from 'rxjs/operators';
import { NgModule, ViewChild, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DatePipe } from '@angular/common';
import * as moment from "moment"; 
import { MultiselectwithsearchComponent } from 'src/app/components/Control/multiselectwithsearch/multiselectwithsearch.component';
//import { parse, format } from 'date-fns';
interface ComboDS {
  Displaymember: string;
  Valuemember: string;
}
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})

export class TicketComponent implements OnInit {

  CID:any;
  flag:any;
  checked = false;
  TicketID:any=0;
  form_template:any;
  myFormGroup:FormGroup;
  companies:any;
  CurLedgerID:any
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  empid:string[]=[];
  employeelist: any;
  Tasklistsub:any;
  visible:boolean=false
  template:any;
  ActiviteID:any;
  TagID:any;
  Comment:any='';
  Commentvisible:boolean=true;
   isEditable = false;
   temp4:any;
   temp =[];
   TemplateGroupID:any
   TemplateID:any
   LoadCombo:any;
   ticketlistM1:any;
   eventID:any;
   public filteredList="";
   ComboDS: ComboDS[];
   showSpinner:any;
   UserID:any;
   valueContent: string;
    editorValueType: string;

    isDisabled: boolean = false;

    originalDate: string = "Oct 17 2023 12:00AM";
    convertedDate: string;
    

    multiselectinputvalue:any;
 
    name1 = 'Angular 4';
    config = {
      uiColor: '#ffffff',
      toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles'] },
      { name: 'paragraph', groups: ['list', 'indent', 'align'] },
      { name: 'styles' },
      { name: 'colors' }
     ],
   removePlugins: 'elementspath,save,magicline',
   removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
      
   }
   
  constructor(private datePipe: DatePipe,
    public service: HttpserviceService,public notificationService: NotificationService,private httpClient: HttpClient,
    public dialogRef: MatDialogRef<TicketComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) 
    {
      this.flag = this.data.flag;
      this.template=this.data.template;
      this.TicketID=this.data.TicketID
      this.ActiviteID=this.data.ActiviteID
      this.TagID=this.data.TagID
      this.TemplateGroupID=this.data.TemplateGroupID,
      this.TemplateID=this.data.TemplateID

      let actvityflag=this.ActiviteID.split("_",4)

      if (this.flag=='ADD' || actvityflag[1]=='EDIT' )
      {
           this.Commentvisible=false;
      }

      let newList1 = [];
              let temp
            
               for (var i = 0; i < this.template.length; i++) 
              {
                 temp={"type":this.template[i].ControlType,"ID":this.template[i].ID,"label":this.template[i].LabelName,"mandatory":this.template[i].Mandatory,"MandatorySymbol":this.template[i].MandatorySymbol,"Visibility":this.template[i].parent=="0" ? true: false,"parent":this.template[i].parent,"options":(JSON.parse(this.template[i].Value))}
                newList1.push(temp);
              }
            //  console.log('options',newList1)
              this.form_template=newList1
              let group={}    
              this.form_template.forEach(input_template=>{
                if (input_template.mandatory==="required")
                {
                  group[input_template.ID]=new FormControl('',Validators.required); 
                  if(input_template.type==='multiselect')
                    group[input_template.ID+'ID']=new FormControl('',Validators.required); 
                }
                else
                {
                  group[input_template.ID]=new FormControl(''); 
                  if(input_template.type==='multiselect')
                    group[input_template.ID+'ID']=new FormControl(''); 
                }
                 
              })
              this.myFormGroup = new FormGroup(group);
      
    }    

    onValueTypeChanged({ addedItems }) {
        this.editorValueType = addedItems[0].text.toLowerCase();
    }

    valueChange(value) {
        this.valueContent = value;
    }

  ngOnInit() 
  {
    this.isDisabled = false;
    this.dialogRef.updateSize('60%');
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserID=curUser.userid;

    if (this.flag=='ADD')
    {
      //to do
    //   //console.log('ass',this.form_template)
      //  this.myFormGroup.controls.Status.setValue('5')
    //  // console.log('JSON.stringify(curUser.userid',JSON.stringify(curUser.userid))
        this.myFormGroup.controls.Created.setValue(JSON.stringify(curUser.userid))
    //   this.myFormGroup.controls.Prefix.setValue('1')

    }

    if (this.flag=='EDIT')
    {this.Loaddetails();}

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );  
      // this.myFormGroup.controls['DESC'].setValue('<ol><li><font face="Arial"><u>dfdfdfd</u></font></li></ol>')
  }
  private _filter(_value: string): string[] {
      return this.empid.filter(option => option);   
  }

  Loaddetails()
  {
    let params = {
      cid:this.CID,
      flag:this.flag,
      TicketID:this.TicketID,
      TemplateID:this.TemplateID,
      UserID:this.UserID,
      TagID:this.TagID,
      UserName:this.CurLedgerID,
      ActiviteID:this.ActiviteID,
      TemplateGroupID:this.TemplateGroupID

       }
          this.service.httpPost('/TicketCreator/GetTicketCreator?',params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){

                 let TaskListSub=_.filter(result.respdata.TaskListSub)

                 //console.log('TaskListSub',TaskListSub);

                for (var i = 0; i < TaskListSub.length; i++) 
                {
                  let ColumnName=TaskListSub[i].Tag                   
                  if(TaskListSub[i].ControlType=='multiselect' && TaskListSub[i].Answer!=null)
                  {                           
                    //console.log('TaskListSub[i].Answer',TaskListSub[i].Answer)
                    const validJsonString = TaskListSub[i].Answer.replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":');              
                    this.multiselectinputvalue=JSON.parse(validJsonString);
                    const displayMembers = this.multiselectinputvalue.map(item => item.Displaymember);                    
                    const result2 = displayMembers.join(',');                                                           
                    this.myFormGroup.controls[ColumnName].setValue(result2);
                    const displayMembers2 = this.multiselectinputvalue.map(item => item.Valuemember);                                        
                    const result3 = displayMembers2.join(',');
                    //console.log('result2',result3)           
                    this.myFormGroup.controls[ColumnName+'ID'].setValue(result3);
                  } 
                  else if(TaskListSub[i].ControlType=='checkbox')
                  {
                    //console.log('checkbox');
                    if(TaskListSub[i].Answer==1)
                      this.myFormGroup.controls[ColumnName].setValue(true);
                    else
                      this.myFormGroup.controls[ColumnName].setValue(false);
                  } 
                  else if(TaskListSub[i].ControlType=='Date')
                  {   
                      this.myFormGroup.controls[ColumnName].setValue(TaskListSub[i].Answer);                   
                  } 
                  else 
                  {
                    // console.log('TaskListSub[i].Answer',TaskListSub[i].Answer);
                    // console.log('ColumnName',ColumnName)
                    this.myFormGroup.controls[ColumnName].setValue(TaskListSub[i].Answer)
                  }                  
                  this.form_template[i].Visibility=true 
                }
                // console.log("c",result.respdata.TicketActivity)
                if(result.respdata.TicketActivity.length>0)
                {
                  this.Comment=result.respdata.TicketActivity[0].Comment
                }
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  convertToDate(dateString: string): Date {
    const formattedDate = this.datePipe.transform(dateString, 'MMM dd yyyy hh:mm a', 'en-US');
    return new Date(formattedDate);
  }
  
 
  onSubmit()
  {
    if (this.myFormGroup.invalid) {
      this.notificationService.warn('Please fill all mandatory fields');     
      return;
      }  
    this.isDisabled = true;
    this.showSpinner=true;
    const paramvalue=this.myFormGroup.value;
   // console.log("form",paramvalue)
    let assessmentvalue = [];
    var ColumnName
    let Value
    let Value2

      // for (var i = 0; i < this.form_template.length; i++) {
      //   ColumnName= this.form_template[i].ID;
      //   // Value=paramvalue[ColumnName]
      //   // Value="1,2,3"
        
      //   console.log("formValue",paramvalue[ColumnName].length)
      // assessmentvalue.push({ColumnName,Value});
      // }
      //console.log('this.form_template[i].ControlType',this.form_template)
      for (var i = 0; i < this.form_template.length; i++) {
        
          if(this.form_template[i].type=='multiselect')  {          
            ColumnName= this.form_template[i].ID;
            Value=paramvalue[this.form_template[i].ID+'ID']
            }
            else{
                if(this.form_template[i].ID=='Private')
                {
                  ColumnName= this.form_template[i].ID;
                // console.log("checkbox value 1", paramvalue[ColumnName])
                  Value=paramvalue[ColumnName];
                  if(paramvalue[ColumnName]==true)
                    Value=1;
                  else
                    Value=0 ;
                  // console.log("checkbox value 2",Value)
                }
                else if(this.form_template[i].type=='Date')
                {
                  ColumnName= this.form_template[i].ID;
                  Value=this.datePipe.transform(paramvalue[ColumnName], 'MM-dd-yyyy');
                 // console.log("Date value",Value)
                }
                else
                {
                  ColumnName= this.form_template[i].ID;
                  Value=paramvalue[ColumnName]
                  // console.log("Value",Value)
                  // console.log("length",Value.length)
                }
            }
        // let Value1
        // if (Value.length>0 )
        // {
        
        //   // for (var i = 0; i < paramvalue[ColumnName].length; i++)
        //   // {
            
        //   //   Value1=Value1 + paramvalue[ColumnName][i]
        //   // }

        // }
        // else
        // {
        //   Value1=Value
        // }

     if(Value != null)
      {
         //console.log('hi',Value)
        Value2=Value.toString();
        assessmentvalue.push({ColumnName,Value2});
      }
        // Value="1,2,3"
        //console.log("formValue",Value2)
       
       // console.log("dt",assessmentvalue)
      }

      {
        let  paramsExtra;
        paramsExtra = {
          CID: this.CID,
          Flag: this.flag,
          TemplateID:this.TemplateID,
          CreatorFlag:0,
          TicketID:this.TicketID,
          UserName: this.UserID,
          dt:assessmentvalue,
          Activity:this.ActiviteID,
          Comment:this.Comment,
          ActiviteID:this.ActiviteID
          
        };
        //console.log("paramsExtra",paramsExtra);
        this.service.httpPost('/TicketCreator/SetTicketCreator',paramsExtra)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
          this.showSpinner=true;
            setTimeout(() =>{
              this.showSpinner=false;
            },
            800)
          if (users.errno === 0){
            this.notificationService.success(users.errdesc+ '  Ticket No: ' +users.respdata);       
            // this.onClear() ;
            this.onCloseDialog()
          }
          else{
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });

      // console.log("submit",assessmentvalue)
  }
 }

 getCurrentDateFormatted(): string {
  const currentDate = new Date();
 // return '15-03-2024'
  return this.datePipe.transform(currentDate, 'yyyy-MM-dd');
}

async datasource()
{
  let parent ;
  let parentData ;
  let parentID;
   parent =_.where(this.template, {ID: this.eventID})
  //  console.log('parent',parent)
  
  //  console.log('myFormGroup',this.myFormGroup)
  //  console.log('this.temp',this.myFormGroup.get(parent[0].parent))
  //  parentData=_.where(this.temp, {name: parent[0].parent})
  parentData=this.myFormGroup.get(parent[0].parent)
 //console.log('parentData',parentData.length)
   if(parentData !==null)
   {
    parentID=parentData.value
    //console.log('parentID',parentID);
   }
   let params = {
    cid:this.CID,
    flag:"SelectedLoadCombo",
    //TicketID:parentID,
    TicketID:this.TicketID,
    TemplateID:this.TemplateID,
    UserName:this.CurLedgerID,
    TagID:this.eventID,
    ActiviteID:this.ActiviteID,
    TemplateGroupID:this.TemplateGroupID,
    ParentID:parentID,
    //FilterID
    
     }
     //console.log('params',params);
        // const query = `cid=${params.cid}&flag=${params.flag}&TaskID=${params.TicketID}&TemplateID=${params.TemplateID}&UserID=${params.UserID}&LoginFlag=${params.LoginFlag}&ActiviteID=${params.ActiviteID}`;
      const dt = await this.service.httpPost('/TicketCreator/GetTicketCreator?', params)
        .toPromise().then(data => {
          let result: any;
          result = data;
          if (result.errno === 0) {
            // event.options= this.ticketlistM1
            this.LoadCombo = result.respdata.LoadCombo;
            // console.log('this.LoadCombo',this.LoadCombo);
          }
          // else{
          //   this.notificationService.success(result.errdesc); //':: Problem details');
          // }
        },
          (_error: Error) => {
            // if (error instanceof BadInput)           
            //   console.log(error);
            // else throw error;       
          });
  
}

 async onClick(event)
 {
  //console.log('onClick')  
    event.options =[{Valuemember: 1, Displaymember: ''}]
    
    this.eventID=event.ID
     //console.log('event.ID',event.ID)
     await this.datasource();
     //console.log('this.LoadCombo',this.LoadCombo)
    event.options =this.LoadCombo
 }

 async onParentClick(value,event)
 {
  //console.log('onParentClick')  
  if(value!='')
  {
    event.options =[{Valuemember: 1, Displaymember: ''}]
     this.eventID=event.ID
    let params = {
      cid:this.CID,
      flag:"LoadClosedTicket",
      //TicketID:parentID,
      TicketID:value,
      TemplateID:this.TemplateID,
      UserName:this.CurLedgerID,
      TagID:this.eventID,
      ActiviteID:this.ActiviteID,
      TemplateGroupID:this.TemplateGroupID,
      ParentID:'',
      //FilterID
      
       }
       //'LoadClosedTicket',params);
          // const query = `cid=${params.cid}&flag=${params.flag}&TaskID=${params.TicketID}&TemplateID=${params.TemplateID}&UserID=${params.UserID}&LoginFlag=${params.LoginFlag}&ActiviteID=${params.ActiviteID}`;
        const dt = await this.service.httpPost('/TicketCreator/GetTicketCreator?', params)
          .toPromise().then(data => {
            let result: any;
            result = data;
            if (result.errno === 0) {
              // event.options= this.ticketlistM1
              this.LoadCombo = result.respdata.LoadCombo;
            }
            // else{
            //   this.notificationService.success(result.errdesc); //':: Problem details');
            // }
          },
            (_error: Error) => {
              // if (error instanceof BadInput)           
              //   console.log(error);
              // else throw error;       
            });
            event.options =this.LoadCombo
  }   
 }

 async onKey(value,event)
 {
  this.eventID=event.ID
  await this.datasource();
 event.options =this.LoadCombo

  // console.log('fff',opt.options.slice())
  event.options=this.search(value,event.options) 

  
 }
 search(value: string,opt) {
  let filter = value.toLowerCase();
  //console.log('filter',filter)
  return opt.filter(option =>
    option.Displaymember.toLowerCase().includes(filter)
  );
}
 
changeEvents(event)
  {
    
    let parent ;
    
    let index1 = this.form_template.findIndex(d => d.parent === event.source.ngControl.name);
    if (index1 > -1)
    {
      this.form_template[index1].Visibility=true
    }
  
    let index = this.temp.findIndex(d => d.name === event.source.ngControl.name); //find index in your array
    if (index > -1) {
      this.temp.splice(index, 1);//remove element from array

      parent =_.where(this.template, {parent: event.source.ngControl.name})
     

      if(parent.length>0)
      {
        this.myFormGroup.get(parent[0].ID).setValue('');
      }
    }

 let temp1={"name":event.source.ngControl.name,"value":event.value};
  this.temp.push(temp1)

  }
  onClear()
  {
    this.isDisabled = false;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.myFormGroup.reset(); 
   // this.myFormGroup.disable();  
    this.visible=false;
    this.TicketID=0;
    this.myControl.reset();
    this.myFormGroup.controls.Status.setValue('5')  
    this.myFormGroup.controls.Created.setValue(JSON.stringify(curUser.userid))
  }
  onCloseDialog()
  {
    // this.service.form.reset();
    // this.service.initializeFormGroup();
    this.dialogRef.close({event: 'CLEAR'});
  }

  //=======================================
  // Multi select relavent functionality //
  //=======================================

  async onKeyPress(event) {

    console.log('event',event)
    console.log('this.myFormGroup.',this.myFormGroup)
    let TagID ;
    let TagIdData ;
    TagID =this.myFormGroup.get(event.ID)
    TagIdData =this.myFormGroup.get(event.ID+'ID')  
    // console.log('this.myFormGroup',this.myFormGroup)
    // console.log('event.ID',event.ID,TagID.value)
    if(TagID.value!=null)
    {
      const displayMembers = TagID.value.split(',');
      const valueMembers = TagIdData.value.split(',').map(Number); // Convert string numbers to actual numbers
    // Combine displayMembers and valueMembers into an array of objects
    this.multiselectinputvalue= displayMembers.map((displayMember, index) => ({
      Displaymember: displayMember,
      Valuemember: valueMembers[index]
    }));
    }
    //console.log('myFormGroup',this.myFormGroup)
    event.options =[{Displaymember:'',Valuemember: 1}]
    this.eventID=event.ID
    await this.datasource();
    event.options =this.LoadCombo
  
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      TicketID: this.TicketID,
      ActiviteID: this.ActiviteID,
      TagID: 'INTERNAL',
      TemplateGroupID: this.TemplateGroupID,
      TemplateID: this.TemplateID,
      loadCombo:event.options,
      inputcomboValue:this.multiselectinputvalue,
      FormName:'Ticket'
    }     
    //console.log('dialogConfig',dialogConfig)
    const dialogRef = this.dialog.open(MultiselectwithsearchComponent, dialogConfig)
            dialogRef.afterClosed().subscribe(result => {
              //this.flag = 'HomePage'
              //console.log('result.data',result.data);
              if(result.event=='LOAD'){
                this.multiselectinputvalue=result.data;
                // Step 3: Extract the Displaymember values
                const displayMembers = result.data.map(item => item.Displaymember);
  
                // Step 4: Join the values into a comma-separated string
                const result2 = displayMembers.join(',');

                 // Step 3: Extract the Displaymember values
                 const displayMembers2 = result.data.map(item => item.Valuemember);
  
                 // Step 4: Join the values into a comma-separated string
                 const result3 = displayMembers2.join(',');
                this.myFormGroup.controls[this.eventID].setValue(result2);
                this.myFormGroup.controls[this.eventID+'ID'].setValue(result3);
              }
            
            });         

  }

}
