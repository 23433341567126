import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { UserService } from './../userValidate';
import { Component, OnInit } from '@angular/core';
import {FormControl,FormBuilder,FormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';




@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  panelOpenState = false;
  step = 0;

  model: any;
  groupname: string;
  
  flag: string = "ADD";
  cid: number = 101;
  userid: number = 0;
  useridold: number = 0;
  updatedby: number;
  updateddate: Date;
  jsondt: any;
  templategroupid:any;
  timezone:any;
  merchantlist:any;
  usertype:any;
  worklocation:any;
  public filteredList5
  public filteredList6


  groupselectedValue: any;
  companies: Company[];  
  languages: Language[];
  groups: Group[]; 
  dataSource: any;
  employees: Employee[];

  companyarray:[];

  // Appaccess = [
  //   { name: 'ERP' },
  //   { name: 'CRM_WEB' },
  //   { name: 'CRM_MA' }, 
  //   { name: 'DASHBOARD' }    
  // ];

  tempAppaccess = []='{"ERP":false,"CRM_WEB":false,"CRM_MA":false,"DASHBOARD":false}'
  form: FormGroup;

  constructor(private fb: FormBuilder,
    public service: UserService, 
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<UserComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.flag = this.data.flag;
      this.userid =  this.data.userid;
      this.useridold =  this.data.useridold;
      //console.log('DIALOFDATA',this.data)

      // this.form = this.fb.group({
      //   ERP: [false],
      //   CRM_WEB: [false],
      //   CRM_MA: [false],
      //   DASHBOARD: [false]
      // });
  }

  ngOnInit(): void {  
    this.getUserDefaultLoads();    
  }



  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }  

  getUserDefaultLoads(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      tablename: "EMPLOYEE",
      type: "''",
      condition: "InActive='False' or InActive='True' ",
      menuid: "default",
      withinactive: "false"
    }
    this.service.getUserDefaultLoads(params)
    .subscribe(
      data => {
        var result = data as any;
        result = data;
        
        this.groups = result.respdata.groups;
        this.languages = result.respdata.languages;
        this.companies = result.respdata.companies;
        this.employees = result.respdata.employees;
        
        this.templategroupid=result.respdata.templategroupid;
        this.timezone=result.respdata.timezone;
        this.merchantlist=result.respdata.merchantlist;
        this.usertype=result.respdata.usertype;
        this.worklocation=result.respdata.worklocation;


        this.filteredList5= this.employees.slice();
        this.filteredList6= this.merchantlist.slice();


        if(this.flag=='ADD')
           this.service.form.controls.usertype.setValue(this.usertype[0].Usertype)

      //  console.log('result',result.respdata)  

        this.service.setDefaultValues();
        //set this.companies AllowAccess to true from service.ts companies array SiteID  
        if (this.flag == 'EDIT'){
          var companiesservice = this.service.companies;
          for (let siteid of companiesservice) 
          {
            for (const comp in this.companies) 
            {  
              var val = result.respdata.companies[comp];
              if (val.SiteID == siteid)
              {
                val.AllowAccess = true;
              }
            }
          }
        }
       
      }, 
      (error: AppError) => {
        if (error instanceof BadInput)
          this.service.form.setErrors(error.originalError);
        else throw error;       
      });
  }

  onClear(){
    this.service.form.reset();    
    this.service.initializeFormGroup();    
  }

  // getSerializedString(): string {
  //   return JSON.stringify(this.service.form.value);

    
  // }

  getSerializedString(): string {
    const selectedValues = {
      ERP: this.service.form.get('ERP').value==""?false:this.service.form.get('ERP').value,
      CRM_WEB: this.service.form.get('CRM_WEB').value==""?false:this.service.form.get('CRM_WEB').value,
      CRM_MA: this.service.form.get('CRM_MA').value==""?false:this.service.form.get('CRM_MA').value,
      DASHBOARD: this.service.form.get('DASHBOARD').value==""?false:this.service.form.get('DASHBOARD').value,
    };
    return JSON.stringify(selectedValues);
  }

  // getInvertedValueString(): string {
  //   const formValue = this.form.value;
  //   const invertedValues = {};
  //   for (const key in formValue) {
  //     if (formValue.hasOwnProperty(key)) {
  //       invertedValues[key] = !formValue[key];
  //     }
  //   }
  //   return JSON.stringify(invertedValues);
  // }

  onSubmit(){
    if (this.service.form.valid){
      var curDate=new Date();
      let paramsFormValue = this.service.form.value;         
      
      const serializedString = this.getSerializedString();
   // console.log('sdg',serializedString); 
    // const invertedValueString = this.getInvertedValueString();
    // console.log('sdg',invertedValueString);

      let paramsExtra = {
        flag: this.flag,
        cid: JSON.parse(sessionStorage.getItem('cid')),
        userid: this.userid,
        useridold: this.useridold,
        defaultsiteid: JSON.parse(sessionStorage.getItem('cid')),
        lastupdatedby: 1,
        lastupdateddate: curDate,
        jsondt: this.companies,
        applicationaccess:serializedString
      };

      let params = Object.assign(paramsFormValue, paramsExtra);
      console.log('PARAMS',params)
      this.service.create(params)
      .subscribe(
        (data) => {
          var users: any;
          users = data; //result.users;
          if (users.errno === 0){
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.notificationService.success('User details updated successfully');
            
            // need to add userdetails to send back to grid for display
            this.model = { 'GroupID': params.groupid, 'GroupName': this.groupname, 'UserID': users.respdata.userid, 'UserName': params.username, 'Status': 'Active'};  //get the model from the form
            this.onClose(this.model);
            
          }
          else{
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)
            this.service.form.setErrors(error.originalError);
          else throw error;       
        });     
        
    }
  }

  onCloseDialog(){
    

    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
        this.service.form.reset();
        this.service.initializeFormGroup();
        this.dialogRef.close({event: 'CLEAR'});
      }
    });
  }

  onClose(model){
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close({event: this.flag, data: model});
  }
  

  updateCompany(event: { isUserInput:any; 
                source:{value:any;
                  selected:any}})
  {  
    if (event.isUserInput)
    {
      let compid = event.source.value;      
      for (const comp in this.companies)
      {  
        var val = this.companies[comp];
        if (val.SiteID == compid)
        {
          if (event.source.selected === true)
          {
            val.AllowAccess = 'true';
          }
          else
          {
            val.AllowAccess = 'false';
          }
        }
      }
    }
  }
  
  selected(event) {
    let target = event.source.selected._element.nativeElement;
    this.groupname = target.innerText.trim();
  }
}


export interface GroupALL {
  GroupID: string;
  GroupName: string;
  UserID : string;
  UserName: string;
  Status: string;  
}

interface Language {
  LngCode: string;
  Description: string;
}

interface Group {
  GroupID: string;
  GroupName: string;
}

interface Company {
  CompanyName: string;
  SiteID: string;
  AllowAccess : string;
}

interface Employee {
  Name: string;
  ID: string;
  LedgerID: any;
}