import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ProjectconfigurationComponent } from './../projectconfiguration/projectconfiguration.component';
import { ProjectconfigurationService } from '../projectconfigurationValidate';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { GenericService } from './../../../../Services/OtherServices/generic.service';



@Component({
  selector: 'app-projectconfiguration-list',
  templateUrl: './projectconfiguration-list.component.html',
  styleUrls: ['./projectconfiguration-list.component.css']
})
export class ProjectconfigurationListComponent implements OnInit {
  
  projectlist:MatTableDataSource<GroupALL>;

  displayedColumns =['ID','Name','status','Sdate','Edate','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  searchKey: string;
  popupVisible = false;

  ID:string;
  Name:string;
  Client:string;
  Description:string;
  Status:string;
  Sdate:string;
  Edate:string;
  ContactDesignation:string;
  ContactEmail:string;
  ContactMobile:string;
  ContactPerson:string;
  ContactTelephone:string;
  DayHours:string;
  Dependency:string;
  entity:any;
  Rolelist:any;
  companies: any;
  CID:any;
  


  constructor(private service:ProjectconfigurationService,private NotificationService:NotificationService,
    private generic: GenericService,private dialog: MatDialog,private dialogService: DialogService) { }

  ngOnInit(): void {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.getprojectconfig();
  }

  Submit()
  {
    this.getprojectconfig();
  }
  onCompanySelect(event)
  {
    this.CID=event;
    this.getprojectconfig();

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.projectlist.filter = filterValue.trim().toLowerCase();
  }

  onSearchClear(){
    this.searchKey="";
    this.projectlist.filter="";
  }
  showInfo(data) {
    this.ID=data.ID;
    this.Name=data.Name;
    this.Client=data.Client;
    this.Description=data.description;
    this.Status=data.status;
    this.Sdate=data.Sdate;
    this.Edate=data.Edate;
    this.ContactDesignation=data.ContactDesignation;
    this.ContactEmail=data.ContactEmail;
    this.ContactMobile=data.ContactMobile;
    this.ContactPerson=data.ContactPerson;
    this.ContactTelephone=data.ContactTelephone;
    this.Dependency=data.Dependency;
    this.DayHours=data.DayHours;

    this.popupVisible = true;
  }
  addRow()
  {
    
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus =  true;
     dialogConfig.data = {
       flag: 'ADD',
       pid:0,
       Rolelist:this.Rolelist,
       cid:this.CID
     }
     const dialogRef = this.dialog.open(ProjectconfigurationComponent, dialogConfig)
     dialogRef.afterClosed().subscribe(result => {
      this.getprojectconfig();
    });
  
}
  onEdit(data, _flag){
   const dialogConfig = new MatDialogConfig();
   dialogConfig.disableClose = true;
   dialogConfig.autoFocus =  true;
   dialogConfig.data = {
     flag: _flag,
     pid:data.ID,
     entity:this.entity,
     Rolelist:this.Rolelist,
     cid:this.CID

   }
   const dialogRef = this.dialog.open(ProjectconfigurationComponent, dialogConfig)
   dialogRef.afterClosed().subscribe(result => {
    this.getprojectconfig();
  });        
 }

 exportAsExcel()
  {
    this.generic.excelExport(this.projectlist.data, 'Project Data', 'project list' );
  }

 onDelete(data, _flag){
  data.RtnDate=data.ToDate;
  let data1=data;
 this.dialogService.openConfirmDialog('Are you sure to delete this project?')
 .afterClosed().subscribe(res => {
   if (res) {
     let params = {
       cid: this.CID,
   flag: "DELETE",
   ID:data.ID,
    Name:data.Name,
    Client:"",
    description:data.description,
    status:data.ProjectStatus,
    Sdate:data.Sdate,
    Edate:data.Edate,
    ContactDesignation:data.ContactDesignation,
    ContactEmail:data.ContactEmail,
    ContactMobile:data.ContactMobile,
    ContactPerson:data.ContactPerson,
    ContactTelephone:data.ContactTelephone,
    DayHours:data.DayHours,
    Dependancy:"",
    DependancyID:"",
    location:"",
    listarray:[{Role:"",hours:""}],
    UserID:"",
    GroupID:sessionStorage.getItem("groupid")
     }
     let params1 = Object.assign(params);
     this.service.setprojectconfig(params1)
       .subscribe( 
         data => {
           var result: any;
           result = data;
           this.NotificationService.warn('Project deleted successfully');
           this.Submit();
         }, 
         (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
   }
 });
 
} 
  getprojectconfig()
  {
    let params = {
      cid: this.CID,
      flag:"LOADGRID",
      projectID:"0",
      dayshours:"0"
     
        }
        this.service.getprojectconfig(params)
        .subscribe(data => {
          let result: any;
          result=data;
          if (result.errno === 0){
            this.companies = result.respdata.Table2;
            this.Rolelist= result.respdata.Table1;
            this.projectlist = result.respdata.Table;
            this.projectlist = new MatTableDataSource<GroupALL>(result.respdata.Table);
            this.projectlist.sort = this.sort;
            this.projectlist.paginator = this.paginator;
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
  }
}
export interface GroupALL {
  ID: string;
  Name: string;
  status : string;
  

}
