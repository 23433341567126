import { FormBuilder, FormGroup } from '@angular/forms';
import { Employee, Task, Service } from '../app.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { User } from 'src/app/Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
//import { DxScrollViewModule, DxSortableModule,DxTemplateModule } from 'devextreme-angular';
import * as _ from 'underscore';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { KanbanuserinitialComponent } from '../../kanbanuserinitial/kanbanuserinitial.component';
import { KanbanboardsearchComponent } from '../../kanbanboardsearch/kanbanboardsearch.component';
import { MatStepper } from '@angular/material/stepper';
import { CommentComponent } from '../../Ticket/comment/comment.component';
import { attachmentComponent } from '../../Ticket/attachment/attachment.component';
import { TaskbyviewComponent } from '../../CustomsReport/taskbyview/taskbyview.component';
import { TicketComponent } from '../../Ticket/ticket/ticket.component';
import { EmiteventserviceService } from 'src/app/Services/OtherServices/emiteventservice.service';
import { DialogforreportsComponent } from 'src/app/components/dialogforreports/dialogforreports.component';
import { DatePipe } from '@angular/common';
import { ReportRequest } from 'src/app/Modules/report';

@Component({
  selector: 'app-devxkanbanboard',
  templateUrl: './devxkanbanboard.component.html',
  styleUrls: ['./devxkanbanboard.component.css']
})
export class DevxkanbanboardComponent implements OnInit {
  KanbanFormGroup:any =FormGroup;

  lists: any[] = [];
  LedgerID:any;
  UserID:any;
  TemplateGroupID:any;
  CID:any;
  showSpinner:any;
  //statuses: string[] = ['Open', 'Scheduled', 'In-Progress', 'Waitting', 'Completed'];//['Not Started', 'Need Assistance', 'In Progress', 'Deferred', 'Completed'];//
  statuses: string[] ;//=  ['Not Started', 'Need Assistance', 'In Progress', 'Deferred', 'Completed'];
  employees: Object = {};

  KeyColumn:any;
  ticketassigneedata:any;
  menuListItems:any;  
  statusid:any ;
  UserBoardData:any ; 
  BoardKeyColumn:any;
  TaskData:any ;
  columnID:any ;
  boardID:any;
  templateFilter:any ;
  templateCoulmns:any ;  
  TicketID:any;
  initials: string = '';
  backgroundColor: string = '';

  isdefaultfilter:boolean=true
  beforeKeyColumnValue:any;
  afterKeyColumnValue:any;
  ActiviteID:any;
  TemplateID:any;
  flag:any;
  ticketdetails:any;
  imagelist:any;
  commentlist:any;

  //@ViewChild(KanbanuserinitialComponent) kanbanuserinitial: KanbanuserinitialComponent;

  constructor(
        service: Service,
        private Httpservice: HttpserviceService,
        //private formBuilder: FormBuilder,
        public notificationService: NotificationService,    
        private formBuilder: FormBuilder,    
        private router: Router, 
        private dialog: MatDialog, 
        private eventService: EmiteventserviceService
        ,private datepipe :DatePipe
  ) {

    this.KanbanFormGroup = this.formBuilder.group({           
      fcBoard: [''],
      fcFilterDetails:[''],
      // fcCustomer:['']            
    });
    // const tasks = service.getTasks();
    // console.log('tasks',tasks)
    // service.getEmployees().forEach((employee) => {
    //   this.employees[employee.ID] = employee.Name;
    // });

    // this.statuses.forEach((status) => {
    //   this.lists.push(tasks.filter((task) => task.Task_Status === status));
    // });
  }

  async  ngOnInit() {
    let vCID=sessionStorage.getItem('cid');
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID=curUser.ledgerid;
    this.UserID=curUser.userid;
    this.TemplateGroupID=curUser.TemplateGroupID;
    this.CID=parseInt(vCID);  
    this.Pageload('','','1','');
    // console.log('TaskData',this.statuses)
    // console.log('TaskData',this.TaskData)
    // this.setInitialsAndColor('Powsul S');
   
  }

  Pageload(Customer: any, Employee: any, Flag: any, BoardId: any) {
    this.showSpinner = true;
    let params = {
      CID: this.CID,
      Flag: Flag,
      UserID: this.UserID,
      BoardID: BoardId
    };
    //console.log('params',params)
    this.Httpservice.httpPost('/Kanbanboard/GetKanbanBoardData', params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          this.showSpinner = true;
          setTimeout(() => {
            this.showSpinner = false;
          },
            800)
          if (result.errno === 0) {
            if (Flag == '1') {
              this.lists=[]
              //console.log('result',result)
              sessionStorage.removeItem("FilterData");
              sessionStorage.removeItem("multiselectinputvalue");
              this.KeyColumn = result.respdata.KeyColumn;
              //this.ticketassigneedata = result.respdata.TicketAssigneeData;

              this.menuListItems = result.respdata.Action;
              //console.log('menuListItems',this.menuListItems)
              this.statuses = _.pluck(result.respdata.BoardData, 'ColumnName');
              this.statusid = _.pluck(result.respdata.BoardData, 'ColumnID');
              this.BoardKeyColumn=result.respdata.BoardData;
              this.UserBoardData = result.respdata.UserBoard;
              this.TaskData = result.respdata.Task;
              this.columnID = this.statusid;
              this.boardID = this.KeyColumn[0].BoardID;
              this.KanbanFormGroup.controls.fcBoard.setValue(JSON.parse(this.boardID));
              this.templateFilter = result.respdata.TemplateFilter;
              sessionStorage.setItem("templateFilter",JSON.stringify(this.templateFilter))
              this.templateCoulmns = result.respdata.TemplateSubData;
              sessionStorage.setItem("FilterData", result.respdata.Filter[0].FilterDetails2);  

              // if(this.boardID==result.respdata.DefaultView)    
              //   this.setDefault=true;         
              // console.log('this.FilterDetails',result.respdata.Filter[0].FilterDetails2);
              // console.log('this.data',result.respdata.Filter[0].data);
              this.KanbanFormGroup.controls['fcFilterDetails'].setValue(result.respdata.Filter[0].data);
              // let ticketID=_.pluck(result.respdata.Task, 'TicketID'); 
              // this.LoadKanbanBoard(result.respdata.Task ,this.statusid);  
              
              
              const updatedTasks = this.TaskData.map(task => {
                const assigneeObj = JSON.parse(task.sys_assignee);
                return {
                    ...task,
                    sys_assignee: assigneeObj
                };
              });
            
              //console.log('updatedTasks',updatedTasks)
              
              this.statuses.forEach((status) => {
              this.lists.push(updatedTasks.filter((task) => task.KeyColumn === status));
              });

            }
            else if (Flag == 'Refresh')
              //this.LoadKanbanBoard(result.respdata.Task ,this.statusid);
              console.log('taskpageload', result.respdata.Task, this.statusid)
          }
          else {
            this.notificationService.success(result.errdesc);
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log(error);
          else throw error;
        });
  }    

  onListReorder(e) {
    //console.log('before status',this.statuses);
    const list = this.lists.splice(e.fromIndex, 1)[0];
    this.lists.splice(e.toIndex, 0, list);

    const status = this.statuses.splice(e.fromIndex, 1)[0];
    this.statuses.splice(e.toIndex, 0, status);
   // console.log('after status',this.statuses);
  }

  onTaskDragStart(e) {
    e.itemData = e.fromData[e.fromIndex];
    const beforeresult = this.BoardKeyColumn.find(item => item.ColumnName === e.itemData.KeyColumn);
    this.beforeKeyColumnValue=beforeresult.ColumnID
    //this.beforeKeyColumnValue=e.itemData.KeyColumn;
    //console.log('e.onTaskDragStart',e.itemData.KeyColumn);
  }

  onTaskDrop(e) {    
    e.itemData.KeyColumn =this.statuses[e.element.columnIndex]        
    e.fromData.splice(e.fromIndex, 1);
    e.toData.splice(e.toIndex, 0, e.itemData);   
    //this.afterKeyColumnValue=e.itemData.KeyColumn;    
    this.TicketID=e.itemData.TicketID;
    //console.log('tikcet',e.itemData.TicketID);
    const afterresult = this.BoardKeyColumn.find(item => item.ColumnName === e.itemData.KeyColumn);
    this.afterKeyColumnValue=afterresult.ColumnID
    //console.log('e.itemdata',e.itemData);
   // const getAssigneeData=e.itemData.sys_assignee.split(',').map(Assignee => Assignee.trim());
   // console.log('result',afterresult.ColumnID);
    //console.log('this.KeyColumn[0].Column3',this.KeyColumn[0].Column3)
    //console.log('this.KeyColumn[0].KeyColumn',this.KeyColumn[0].KeyColumn)
    this.save();
  }


  /*To create an initial circle with a different color code in Angular, 
  you can use a combination of HTML, CSS, and Angular*/

  // setInitialsAndColor(name: string): void {
  //   this.initials = this.getInitials(name);
  //   this.backgroundColor = this.getRandomColor();
  // }

  getInitials(firstName: string, lastName: string): string {   
    if(firstName!=''){
    const firstInitial = firstName[0];
    const secondInitial = lastName ? lastName[0] : '';
    return `${firstInitial}${secondInitial}`.toUpperCase();
    }
  }

  // getRandomColor(): string {
  //   const letters = '0123456789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // }



  setDefaultView()
      {
          this.showSpinner=true;          
          let params = {        
            CID:this.CID,      
            Flag:'ADD',
            UserID	:this.UserID  ,
            KeyColumn:'Kanban',
            BoardID:   this.KanbanFormGroup.get('fcBoard').value
          }
        this.Httpservice.httpPost('/Kanbanboard/SetDefaultView',params)
        .subscribe(
        (data) => {   
          let result: any;
          result = data;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          if (result.errno === 0) {  
            this.notificationService.success(result.errdesc); 
          }
          else {
            this.notificationService.warn(result.errdesc); 
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
      }

      BoardRefresh(){      
        this.KanbanFormGroup.controls.fcFilterDetails.setValue('');
        this.Pageload('','',"1",this.KanbanFormGroup.get('fcBoard').value);
      }


      Filter()
       {
          //this.Pageload('','',"Refresh");    
         // this.service.initializeFormGroup();
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.width= '30%';
          dialogConfig.height='85%',
          dialogConfig.position= { right: '0' }, 
          // dialogConfig.width = "60%";
          dialogConfig.data = {
            flag: 'Filter',
            userid: this.UserID,
            ledgerid: this.LedgerID,
            cid:this.CID,
            keycolumn:this.KeyColumn[0].KeyColumn,
            //textdataFlag:this.KanbanFormGroup.get('fcFilterDetails').value==''?'0':'1'
            textdataFlag:this.isdefaultfilter==true?'0':'1',
            BoardID:   this.KanbanFormGroup.get('fcBoard').value,
            template: JSON.parse(sessionStorage.getItem("templateFilter")||"[]"),//this.templateFilter,
            TemplateID:1,
            dtSelectedFilterData:sessionStorage.getItem("FilterData"),
            
            multiselectinputData:sessionStorage.getItem("multiselectinputvalue"),
            templateColumns:this.templateCoulmns
          }

          console.log('dialogConfig',dialogConfig)

          const dialogRef = this.dialog.open(KanbanboardsearchComponent,          
            dialogConfig)
          dialogRef.afterClosed()
          .subscribe(res => {
           //console.log('res',res,this.KeyColumn[0].KeyColumn);
           if(res.event!='CLEAR'){
          //console.log('JSON.stringify(res.sessionDt)',JSON.stringify(res.sessionDt))
          sessionStorage.setItem("FilterData", JSON.stringify(res.sessionDt));  
          sessionStorage.setItem("SearchData", JSON.stringify(res.dt));  
          sessionStorage.setItem("multiselectinputvalue", JSON.stringify(res.sessionmultiselectinputvalue));  
          //let selectdData=res.dt;
          this.KanbanFormGroup.controls['fcFilterDetails'].setValue(res.dtdispalyvalue.map(item => item.DisplayValue).join(', '))
          
          this.statusid=res.statusid;
          

          let params = {
            CID:this.CID, 
            Flag: 'Filter' ,   
            UserID	:this.UserID   ,
            BoardID:   this.boardID ,   
            dtRequestOn: res.dt
          };
          //console.log('params',params)
       
            this.Httpservice.httpPost('/Kanbanboard/GetKanbanBoardData',params)
            .subscribe(
              (data) => {   
                let result: any;
                result = data;
                this.showSpinner=true;
                setTimeout(() =>{
                  this.showSpinner=false;
                },
                800)  
  
                if (result.errno === 0) {  
                    this.lists=[]
                    this.KeyColumn=result.respdata.KeyColumn ;                 
                    //this.ticketassigneedata=result.respdata.TicketAssigneeData ;                   
                    this.menuListItems=result.respdata.Action ;                   
                    this.statuses=_.pluck(result.respdata.BoardData, 'ColumnName');
                    this.statusid=_.pluck(result.respdata.BoardData, 'ColumnID');
                    this.UserBoardData=result.respdata.UserBoard ;     
                    this.TaskData=result.respdata.Task;              
                    this.columnID=this.statusid;
                    this.boardID=this.KeyColumn[0].BoardID;
                    this.KanbanFormGroup.controls.fcBoard.setValue(JSON.parse(this.boardID));   


                    const updatedTasks = this.TaskData.map(task => {
                      const assigneeObj = JSON.parse(task.sys_assignee);
                      return {
                          ...task,
                          sys_assignee: assigneeObj
                      };
                    });
                  
                    //console.log('updatedTasks',updatedTasks)
                    
                    this.statuses.forEach((status) => {
                    this.lists.push(updatedTasks.filter((task) => task.KeyColumn === status));
                    });
                    //this.templateFilter=result.respdata.TemplateFilter;                 
                    //this.LoadKanbanBoard(result.respdata.Task ,this.statusid);                 
                }
                else {
                  this.notificationService.success(result.errdesc); 
                }
              },
              (error: AppError) => {
                if (error instanceof BadInput)           
                  console.log(error);
                else throw error;       
              });            
          
          
          }
          });
       }

       async save(){     
        this.showSpinner=true;        
        const filteredcolumn:any[]= [];
        // this.myArray.map(item => { return {
        //   TicketID: item.ticketID,
        //   statusname: item.ID ,
        //   IndexValue:item.indexorder
        // }});   
        const assigneesForTicket ='';
        //this.getAssigneesForTicket(filteredcolumn[0].TicketID);
        let assessmentvalue = [];
        var ColumnName=this.KeyColumn[0].KeyColumn=='Assignee'?'Assignee':this.KeyColumn[0].KeyColumn        
        let Value2;//=filteredcolumn[0].statusname
     
        // console.log('filteredcolumn',filteredcolumn)
        // console.log('filtassigneesForTicketeredcolumn',assigneesForTicket)
  
        // if(this.KeyColumn[0].Column3=='multiselect'){           
        //   const UserID = filteredcolumn.find(item => item.IndexValue === 1);          
        //   const newArray: string[] = assigneesForTicket.filter(value => value !== UserID.statusname);          
        //   const NewUserID:any=filteredcolumn.find(item => item.IndexValue === 0);
        //   newArray.push(NewUserID.statusname);              
        //   const resultString: string = newArray.join(",");      
        //   assessmentvalue.push({ColumnName,resultString});                   
        // }          
        // else{
        //   Value2=filteredcolumn[0].statusname
        //   assessmentvalue.push({ColumnName,Value2});
        // }    

        let params={
          CID: this.CID,
          Flag: 'EDIT',
          TicketID:this.TicketID,
          ControlType:this.KeyColumn[0].Column3,
          KeyColumn:this.KeyColumn[0].KeyColumn,
          UserID: this.UserID,
          TemplateID:this.KeyColumn[0].TemplateID,
          //Activity:this.KeyColumn[0].ActivityID,
          CreatorFlag:0,
          BeforeDragData:this.beforeKeyColumnValue,
          AfterDragData:this.afterKeyColumnValue,
          //dt:assessmentvalue,
          Comment:'KanbanBoard board drag and drop data',
          ActiviteID:this.KeyColumn[0].ActivityID          
        };

        let data=await this.Httpservice.httpPost('/Kanbanboard/SetKanbanBoardData',params)
          .toPromise ().then(data=> {            
              let result: any;
              result = data;
              this.showSpinner=true;
              setTimeout(() =>{
                this.showSpinner=false;
              },
              800)
              //console.log('result.errno',result.errno)
              if (result.errno === 0) {                                              
                this.KanbanFormGroup.controls.fcFilterDetails.setValue('');
                this.Pageload('','',"1",this.KanbanFormGroup.get('fcBoard').value);             
              }
              // else {
              //   this.notificationService.success(result.errdesc); 
              //}
            },
            (error: AppError) => {
              if (error instanceof BadInput)           
                console.log(error);
              else throw error;       
            }); 
    
      }



      clickMenuItem(menuItem ,Value, stepper: MatStepper){        
        // console.log('menuitem',menuItem)
        // console.log('Value',Value)

        this.TicketID=Value.TicketID;
        this.ActiviteID=menuItem.ActivityID;
        this.TemplateID=menuItem.TemplateID;
        let actvityflag=menuItem.ActivityID.split("_",4)
        this.flag='ACTIVITY'
        if (actvityflag[1]=='COMMENT')
        {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {
            flag: 'EDIT',
            pid:0,            
           template:'',
           TicketID:this.TicketID,
           TemplateID:this.TemplateID
     
          }
          const dialogRef = this.dialog.open(CommentComponent, dialogConfig)
          dialogRef.afterClosed()
        }
        else if (actvityflag[1]=='ATTACHMENT')
        {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {
            flag: 'EDIT',
            pid:0,            
          template:'',
          TicketID:this.TicketID
    
          }
          const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
          dialogRef.afterClosed()
        }
        else if (actvityflag[1]=='VIEW')
        {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;       
          dialogConfig.data = {                 
                pid:0,
                height: '350px',
                width: '70%',                            
                cid:this.CID,
                flag:"View",
                TicketID:this.TicketID,
                TemplateID:this.TemplateID,
                UserName:this.UserID,
                TagID:'External',
                ActiviteID:this.ActiviteID,
                TemplateGroupID:1,   
                Subject:Value.Subject
            }                   
            // const dialogRef =  this.dialog.open(TaskbyviewComponent, dialogConfig)
            // dialogRef.afterClosed().subscribe(result => {     
            //   //this.Submit();     
            // });

            sessionStorage.setItem('activityTicketID', this.TicketID);
            sessionStorage.setItem('TaskSubject', Value.Subject);
            sessionStorage.setItem('TemplateID', this.TemplateID);
            sessionStorage.setItem('ActivityID', this.ActiviteID);
            //sessionStorage.setItem('TemplateIDGroupID', this.TemplateGroupID);
    //console.log('result',result)
    // let item: any = { "name": 'this.TicketID', "type": "FORM", "icon": "arrow_right", "menuid": "STS_134", "parentid": "54", "visible": true };
    //this.eventService.emitMenuSelect(result);
   
      const result= { "name": this.TicketID, "type": "FORM", "icon": "arrow_right", "menuid": "STS_134", "parentid": "54", "visible": true };
      this.eventService.emitMenuSelect(result);
   

        }  
        else if (actvityflag[1]=='Report')
          {
           // console.log('report')
         
            const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            flag: 'Report',
            pid: 0,
    
            template: this.TemplateID,
            TicketID: this.TicketID,
            // geovisible:this.geovisible
    
          }
          const dialogRef = this.dialog.open(DialogforreportsComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {  
        
          if(result!==undefined)
          {
            this.printvalue(result); 
          }
             
        });
    
    
            
    
          } 
        else if (actvityflag[1] !=='ATTACHMENT'  && actvityflag[1] !='Report')
        {  
          //console.log('Other')    ;
          //this.Activityflag='EDIT'
          this.Loaddetails();
        }       
    }

    async printvalue(row)
  {
   await this. GetReportValue();
   console.log('row',row)
    const reportRequest: ReportRequest = {
      CID:this.CID,
      HeaderBanner:row.HeaderBanner,
      reportName: row.FileName,
      TableName:'DynImage',
      dataSet: {
        "imagelist":this.imagelist,
        "TicketComment":this.commentlist,
        "test":[
          {"test1":2},
          {"test1":3}
        ]
      },
      parameters: {
        'pReportName': '12345',
        'pSiteName': '12345',
    'pContractRefNo': this.ticketdetails[0].Field5,
    'pTicketNo':this.ticketdetails[0].Field11==null?"":this.ticketdetails[0].Field11,
		'pTicketDate':this.datepipe.transform(this.ticketdetails[0].CreatedDate,"dd/MM/yyy hh:mm"),
		'pContactonsite': "",
		'pContact1': "",
		'pVisitDate': this.datepipe.transform(this.ticketdetails[0].CreatedDate,"dd/MM/yyy hh:mm"),
		'pTel': "",
		'pComplaint': this.ticketdetails[0].Subject,
		'pComment1': 'Button box remove',
		'pComment2': 'COmment2',
		'pComment3': 'COmment3',
		'pComment4': 'COmment4',
		'pComment5': 'COmment5',
		'pComment6': 'COmment6',
		'pComment7': 'COmment7',
		'pComment8': 'COmment8',
		'pComment9': 'COmment9',
		'pComment10': 'COmment10',
		'pLocation': this.ticketdetails[0].Field4,
		'pEndUserStaffID':"",
		'pEndUserDate': "",
		'pEndUserContact':"",
		'pRTFEngineer': "",
		'pRTFEngineerDate': "",
		'pManagerApproval': "",
		'pManagerApprovalDate': "",
      },
      sendMailFlag: false,
      fromMail: 'example@example.com',
      toMail: 'example@example.com',
      flag: 'EMPLOYEE',
      attachFileName: 'Report.pdf'
    };
    console.log('reportRequest',reportRequest)
    this.Httpservice.httpPost3('/Report/GenerateReport',reportRequest).subscribe((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
       window.open(url); 
    });
  }
  async GetReportValue()
  {
    let params = {
      cid: this.CID,
      flag:'ReportDetails',
      TicketID: this.TicketID,
      TemplateID: 1,
      UserName: this.UserID,
      TagID: 'External',
      ActiviteID: this.ActiviteID,
      TemplateGroupID: 1

    }
   // console.log('vigi',params)

   await this.Httpservice.httpPost('/TicketCreator/GetTicketCreator?', params)
      .toPromise().then(data => {
            let result: any;
            result=data;
            if (result.errno === 0){

         
              this.imagelist=result.respdata.DynImage;
              this.commentlist=result.respdata.Comment;
              this.ticketdetails=result.respdata.Ticket;
            //  console.log('TE',this.imagelist)

              // this.downloadFilesAsZip(this.datasource);
             
              
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
         
  }
    Loaddetails()
      {
        this.showSpinner=true;
        let params = {
        cid:this.CID,
        flag:this.flag,
        TicketID:this.TicketID,
        TemplateID:this.TemplateID,
        UserName:this.LedgerID,
        TagID:'INTERNAL',
        ActiviteID:this.ActiviteID,
        TemplateGroupID:this.TemplateGroupID        
        }
        this.Httpservice.httpPost('/TicketCreator/GetTicketCreator?',params)
        .subscribe(data => {
              let result: any;
                result=data;
                this.showSpinner=true;
                setTimeout(() =>{this.showSpinner=false;},800)
                if (result.errno === 0){    
                    //this.TaskTemplate=result.respdata.TaskTemplate
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.autoFocus =  true;
                    dialogConfig.data = {
                      flag: 'EDIT',
                      pid:0,
                      height: '350px',
                      width: '60%',
                     template:result.respdata.TaskTemplate,
                     TicketID:this.TicketID,
                     ActiviteID:this.ActiviteID,
                     TagID:'INTERNAL',
                     TemplateGroupID:this.TemplateGroupID,
                     TemplateID:this.TemplateID           
                    }
                    const dialogRef = this.dialog.open(TicketComponent, dialogConfig)
                    dialogRef.afterClosed().subscribe(result => {
                      this.flag='HomePage'                   
                    });
                }
                else{
                  this.notificationService.success(result.errdesc); //':: Problem details');
                }
              }, 
              (error: AppError) => {
                if (error instanceof BadInput)           
                  console.log(error);
                else throw error;       
              });
      }

}
