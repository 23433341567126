import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BANKS, Bank } from './demo-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-multiselectwithsearch',
  templateUrl: './multiselectwithsearch.component.html',
  styleUrls: ['./multiselectwithsearch.component.css']
})
export class MultiselectwithsearchComponent implements OnInit, AfterViewInit, OnDestroy {
  
   /** list of banks */
  protected banks: Bank[] = [];

  /** control for the selected bank for multi-selection */
  public bankMultiCtrl= new FormControl<Bank[]>([]);

  /** control for the MatSelect filter keyword multi-selection */
  public bankMultiFilterCtrl2= new FormControl<string>('');

  /** list of banks filtered by search keyword */
  public filteredBanksMulti: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);

  /** local copy of filtered banks to help set the toggle all checkbox state */
  protected filteredBanksCache: Bank[] = [];

  /** flags to set the toggle all checkbox state */
  isIndeterminate = false;
  isChecked = false;

  @ViewChild('multiSelect', { static: true })  multiSelect!: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  multiselectedvalue:any
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MultiselectwithsearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {  
        this.banks=this.data.loadCombo;
        console.log(' this.multiselectedvalue', this.data.inputcomboValue);
        this.filteredBanksCache=this.data.inputcomboValue
        
  }

  ngOnInit() {

    if(this.multiselectedvalue!='')
      // set initial selection
      this.bankMultiCtrl.setValue(this.filteredBanksCache);

    // load the initial bank list
    this.filteredBanksMulti.next(this.banks.slice());

    // listen for search field value changes
    this.bankMultiFilterCtrl2.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
       // this.setToggleAllCheckboxState();
      });
  }

  ngAfterViewInit() {
   this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredBanksMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.bankMultiCtrl.patchValue(val);
        } else {
          this.bankMultiCtrl.patchValue([]);
        }
      });
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    
    this.filteredBanksMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: Bank, b: Bank) => a && b && a.Valuemember === b.Valuemember;
      });
  }

  protected filterBanksMulti() {
    
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankMultiFilterCtrl2.value;
    if (!search) {
      this.filteredBanksCache = this.banks.slice();
      this.filteredBanksMulti.next(this.filteredBanksCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksCache = this.banks.filter(bank => bank.Displaymember.toLowerCase().indexOf(search) > -1);
    this.filteredBanksMulti.next(this.filteredBanksCache);
  }



  onClear()
  {
  //   this.isDisabled = false;
  //   let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  //   this.myFormGroup.reset(); 
  //  // this.myFormGroup.disable();  
  //   this.visible=false;
  //   this.TicketID=0;
  //   this.myControl.reset();
  //   this.myFormGroup.controls.Status.setValue('5')  
  //   this.myFormGroup.controls.Created.setValue(JSON.stringify(curUser.userid))
  }
  onCloseDialog()
  {
    this.dialogRef.close({event: 'CLEAR'});
  }

  onSubmit(){
    console.log('bankMultiCtrl',this.bankMultiCtrl.value);
    this.dialogRef.close({event: 'LOAD', data: this.bankMultiCtrl.value});
  }

}
