import { DocumentcontrolComponent } from './../documentcontrol/documentcontrol.component';
import { DocumentdownloadComponent } from './../documentdownload/documentdownload.component';
import { getElement } from 'devextreme-angular';
import { filter } from 'rxjs/operators';
import { Component, OnInit,ViewChild, } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder,FormArray,AbstractControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import * as _ from 'underscore/underscore-min.js';
import { MatSort} from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { DocumentcontrolValidate } from '../documentcontrolvalidate';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { User } from './../../../../Modules/user';
import { GenericService } from './../../../../Services/OtherServices/generic.service';

@Component({
  selector: 'app-documentcontrollist',
  templateUrl: './documentcontrollist.component.html',
  styleUrls: ['./documentcontrollist.component.css']
})
export class DocumentcontrollistComponent implements OnInit {

  datasource:MatTableDataSource<GroupALL>;
  datasource1:any;
  companies:any;
  searchKey: string;
  limit:any=5;
  CID:any;
  displayedColumns =['TaskID','Name','TaskCategory','Desc1','StartDate','DueDate','PopUpDays','Status','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  constructor(private formBuilder: FormBuilder, private generic: GenericService,
    public DcValidate: DocumentcontrolValidate,
    public notificationService: NotificationService,
    private dialog: MatDialog,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
   this.getdocumentdetails();
  }

  Submit()
  {
    this.getdocumentdetails();
  }
  onCompanySelect(event)
  {
    this.CID=event;
    this.getdocumentdetails();

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  exportAsExcel(){
    this.generic.excelExport(this.datasource.data, 'Document List', 'Document List' );
  }

  onSearchClear(){
    this.searchKey="";
    this.datasource.filter="";
  }
  addRow()
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.DcValidate.initializeFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: 'ADD',
      Name:curUser.empname,
      TaskID:0,
      LedgerID:curUser.ledgerid,
      cid:this.CID,
      // pid:0,
      // Rolelist:this.Rolelist,
      panelClass: 'custom-dialog-container',
       FormType:'HR'
    }
    const dialogRef = this.dialog.open(DocumentcontrolComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      this.getdocumentdetails();     
    });
  }
  onlimit()
  {
    this.limit=this.limit + 5;
  }
  onEdit(data, _flag){
    this.DcValidate.populateForm(data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: _flag,
      Name:data.Name,
      TaskID:data.TaskID,
      LedgerID:data.LedgerID,
      cid:this.CID,
       FormType:'HR'
    }
    const dialogRef =this.dialog.open(DocumentcontrolComponent, dialogConfig);    
    dialogRef.afterClosed().subscribe(result => {     
      this.getdocumentdetails();     
    });        
  }
  onDelete(data, _flag)
  {
    this.dialogService.openConfirmDialog('Are you sure to delete this document?')
 .afterClosed().subscribe(res => {
   if (res) {
    const  paramsExtra = {
      cid: this.CID,
      flag: "DELETE",
      Slno: '0',
      TaskID:data.TaskID,
      LedgerID: data.LedgerID,
      UserName: 'admin',
      Comments:'',
      FileName:'',
      FilePath:'',
      Extension:'',
      AliasName:'',
      EmpName:'',
      TaskCategory:'',
      StartDate:'',
      enddate:'',
      Status:'',
      NotifyFlag:'',
      PostingDate:''
    };
    const params = Object.assign(paramsExtra);
    // console.log("save",params);
    this.DcValidate.setdocumentcontrol(params)
    .subscribe(
      (data) => {
        let users: any;
        users = data; //result.users;
        // console.log('create:', users.respdata);

        if (users.errno === 0){
          // this.DcValidate.form.reset();
          // this.DcValidate.initializeFormGroup();
          this.notificationService.success('Deleted successfully');
          // this.onClose(this.model);
          this.getdocumentdetails();     
        }
        else{
          this.notificationService.success(users.errdesc); //':: Problem updating user');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)
          console.log('Error: ', error);
          // this.DcValidate.form.setErrors(error.originalError);
        else throw error;
      });
    }
  });
  }

  DocumentView(data, _flag){
    this.DcValidate.populateForm(data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: _flag,
      Name:data.Name,
      TaskID:data.TaskID,
      LedgerID:data.LedgerID,
      cid:this.CID
    }
    const dialogRef =this.dialog.open(DocumentdownloadComponent, dialogConfig);     
   dialogRef.afterClosed().subscribe(result => {        
    this.getdocumentdetails();     
  });     
  

  }

  getdocumentdetails()
  {
      // console.log("dddd",this.LedgerID)
      this.searchKey='';
      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
       let params = {
       cid:this.CID,
       flag:'LOADGRID',
       taskno:0
        }
           this.DcValidate.getdocumentcontrol(params)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

              this.companies=curUser.dtsiteid;
               this.datasource= result.respdata.Table1;
               this.datasource1=result.respdata.Table1;
               this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Table1);
               this.datasource.sort = this.sort;
               this.datasource.paginator = this.paginator;
             }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }
  }

export interface GroupALL {
  Name: string;
}
