import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { TicketComponent } from '../ticket/ticket.component';
import { MatStepper } from '@angular/material/stepper';
import * as _ from 'underscore/underscore-min.js';
import { attachmentComponent } from '../attachment/attachment.component';
import { Platform } from '@ionic/angular';
import { CommentComponent } from '../comment/comment.component';

interface Level {
  id: number;
  Name: string;
}

@Component({
  selector: 'app-ticketlist',
  templateUrl: './ticketlist.component.html',
  styleUrls: ['./ticketlist.component.scss'],

})

export class TicketlistComponent implements OnInit {

  id: number = 10;
  row: number = this.id / 2;
  row1 = Array(this.row).fill(1);
  row2 = Array(3).fill(1).map((_, index) => index + 1);
  matrix = []

  CID:any;
  flag:any;
  Activityflag:any;
  TicketID:any='0';
  datasource:any;
  TaskTemplate:any;
  TicketActivity:any;
  companies:any
  Templatelist:any
  CurLedgerID:any
  selectedValue:any
  isEditable = false;
  TicketActivitylist:any;
  ticketlist:any
  ticketlist1:any
  ticketlist2:any
  ticketlist3:any
  ticketlist4:any
  ticketlist5:any
  ActiviteID:any
  menus1:any;
  TemplateGroupID:any;
  UserType:any;
  TemplateID:any=0;
  AddTemplate:any;
  addbtnvisible:boolean=false
  firstFormGroup:FormGroup;
  steppercount:number=0
  devicevisble:boolean=false
  mobileplatform:boolean=false
  showSpinner:any;
  menuListItems : MatMenuListItem[];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns =['CreatedDate','TicketID','Subject','Priority','Status','Action'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,public plt: Platform)
   { 

    var chunk_size = 2;
    var arr = this.row2;
    var groups = arr.map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
    })
      .filter(function (e) { return e; });
    this.matrix = groups;
  
  this.firstFormGroup = new FormGroup({   
   
    Name: new FormControl('', [Validators.required]) 
    });  

   }
  
  ngOnInit()
   {
    console.log("amjath")
     
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.TemplateGroupID=curUser.TemplateGroupID;
    console.log("TemplateGroupID",this.TemplateGroupID)
    this.flag='PAGELOAD'
    this.ActiviteID='EDIT'
     this. Loaddetails();

   }
   onCompanySelect(event)
  {
    this.CID=event;
    this.Loaddetails();

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
   onTemplateSelect(event)
   {
    this.TemplateID=event;
    this.flag='LOADGRID'
    this.Loaddetails();
   }
   clickMenuItem(menuItem : MatMenuListItem,Value, stepper: MatStepper){
    console.log('amjath');
    this.TicketID=Value

    this.ActiviteID=menuItem.ActivityID
    let actvityflag=menuItem.ActivityID.split("_",4)
   
   
    this.flag='ACTIVITY'
    if(actvityflag[1]=='VIEW')
    {
      this.flag='View'
      this.firstFormGroup.controls.Name.setValue('test');  
      this.isEditable=true
      this.steppercount=1
      stepper.next();
    }
    else if (actvityflag[1]=='ATTACHMENT')
    {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid:0,
        
       template:this.TaskTemplate,
       TicketID:this.TicketID
 
      }
      const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
      dialogRef.afterClosed()
    }
    


    if (actvityflag[1] !=='ATTACHMENT')
    {  
      console.log('Other')    ;
      this.Activityflag='EDIT'
      this.Loaddetails();
    }
    // if (actvityflag[1] !=='COMMENT')
    // {
    //   this.Activityflag='EDIT'
    //   this.Loaddetails();
    // }  
}
Loaddetails()
  {
    this.showSpinner=true;
    let params = {
      cid:this.CID,
      flag:this.flag,
      TicketID:this.TicketID,
      TemplateID:this.TemplateID,
      UserName:this.CurLedgerID,
      LoginFlag:'INTERNAL',
      ActiviteID:this.ActiviteID,
      TemplateGroupID:this.TemplateGroupID
    
       }
       
          this.service.httpPost('/TicketCreator/GetTicketCreator?',params)
          .subscribe(data => {
            let result: any;
            result=data;
            this.showSpinner=true;
            setTimeout(() =>{
              this.showSpinner=false;
            },
            800)
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist
                this.Templatelist=result.respdata.TaskTemplate
                
               
              }
              else if(this.flag=='LOADGRID')
              {
                this.menuListItems=result.respdata.TaskTemplate 
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.TicketList);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
                this.AddTemplate=result.respdata.AddTemplate
               
                    this.addbtnvisible=this.AddTemplate.length>0 ? true :false
               
              }
              else if(this.flag=='View')
              {
              //  console.log("count",result.respdata.TicketListSub)
              var temp
              var panel2
              temp=_.countBy(result.respdata.TicketListSub, 'UIPanel');
              panel2=_.where(temp,'2')
              console.log("panel2", panel2[1]);

              if (this.plt.is('ios') || this.plt.is('android'))     
              {
                console.log("plat",1)
                this.devicevisble= true;
                this.mobileplatform=true;
              }
              else{
                console.log("plat",2)
                this.devicevisble= panel2[1]>0 ? false : true;
                this.mobileplatform=false;
              }

              // this.devicevisble= panel2[1]>0 ? false : true;
               console.log("count", this.devicevisble);
                this.ticketlist1=_.where(result.respdata.TicketListSub, {UIPanel: 1});
                this.ticketlist2=_.where(result.respdata.TicketListSub, {UIPanel: 2});
                this.ticketlist3=_.where(result.respdata.TicketListSub, {UIPanel: 3});
                this.ticketlist4=_.where(result.respdata.TicketListSub, {UIPanel: 4});
                this.ticketlist5=_.where(result.respdata.TicketListSub, {UIPanel: 5});
 
                this.TicketActivitylist=result.respdata.TicketActivity;
                this.menuListItems=result.respdata.TaskTemplate

                let grouplist= result.respdata.Group;
                let grouplist1= _.where(result.respdata.Group, {UIPanel: 1});
                let grouplist2= _.where(result.respdata.Group, {UIPanel: 2});
                let grouplist3= _.where(result.respdata.Group, {UIPanel: 3});
                let grouplist4= _.where(result.respdata.Group, {UIPanel: 4});
                let grouplist5= _.where(result.respdata.Group, {UIPanel: 5});
               
                //----- panel1 ---//
                let newList1 = [];
                let temp1
                for (var i = 0; i < grouplist1.length; i++) 
                {
                  temp1={"name":grouplist1[i].UIGroup,"submenu": _.where( this.ticketlist1, {UIGroup: grouplist1[i].UIGroup})}
                  newList1.push(temp1);
                }
                this.ticketlist1=newList1;

                //----- panel2 ---//
                let newList2 = [];
                let temp2
                for (var i = 0; i < grouplist2.length; i++) 
                {
                  temp2={"name":grouplist2[i].UIGroup,"submenu": _.where( this.ticketlist2, {UIGroup: grouplist2[i].UIGroup})}
                  newList2.push(temp2);
                }
                this.ticketlist2=newList2;

                //----- panel3 ---//
                let newList3 = [];
                let temp3
                for (var i = 0; i < grouplist3.length; i++) 
                {
                  temp3={"name":grouplist3[i].UIGroup,"submenu": _.where( this.ticketlist3, {UIGroup: grouplist3[i].UIGroup})}
                  newList3.push(temp3);
                }
                this.ticketlist3=newList3;

                //----- panel4 ---//
                let newList4 = [];
                let temp4
                for (var i = 0; i < grouplist4.length; i++) 
                {
                  temp4={"name":grouplist4[i].UIGroup,"submenu": _.where( this.ticketlist4, {UIGroup: grouplist4[i].UIGroup})}
                  newList4.push(temp4);
                }
                this.ticketlist4=newList4;

                //----- panel5 ---//
                let newList5 = [];
                let temp5
                for (var i = 0; i < grouplist5.length; i++) 
                {
                  temp5={"name":grouplist5[i].UIGroup,"submenu": _.where( this.ticketlist5, {UIGroup: grouplist5[i].UIGroup})}
                  newList5.push(temp5);
                }
                this.ticketlist5=newList5;
        
              }
              else 
              {
                this.TaskTemplate=result.respdata.TaskTemplate
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus =  true;
                dialogConfig.data = {
                  flag: this.Activityflag,
                  pid:0,
                  height: '350px',
                  width: '60%',
                 template:this.TaskTemplate,
                 TicketID:this.TicketID,
                 ActiviteID:this.ActiviteID,
                 LoginFlag:'INTERNAL',
                 TemplateGroupID:this.TemplateGroupID,
                 TemplateID:this.TemplateID
           
                }
                
                const dialogRef = this.dialog.open(TicketComponent, dialogConfig)
                dialogRef.afterClosed().subscribe(result => {
                  this.flag='LOADGRID'
                  if(this.steppercount==0)
                  {
                    this.Loaddetails();
                  }
                 
            
                  });
                
              }

              
                
               
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  addRow()
  {
    // console.log("TaskTemplate",this.AddTemplate[0].ActivityID)
    this.ActiviteID=this.AddTemplate[0].ActivityID
    this.flag='ADD'
    this.Activityflag='ADD'
    this.TicketID='0'
    this. Loaddetails();
    
  
}
createView()
{
  this.isEditable=false
  console.log("reset")
  this.flag='LOADGRID'
  this.ActiviteID='EDIT'
   this. Loaddetails();
   this.steppercount=0
   this.firstFormGroup.controls.Name.setValue(''); 
}
onAttachment(menuItem : MatMenuListItem,Value)
{
  const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid:0,
        height: '350px',
        width: '60%',
       template:this.TaskTemplate,
       TicketID:this.TicketID
 
      }
      const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
      dialogRef.afterClosed()
}

  Refresh(){
    this.TemplateID=this.TemplateID;
    console.log('fdsgds',this.TemplateID);
    this.flag='LOADGRID'
    this.Loaddetails();
  }

}
export class MatMenuListItem {
  ActivityID: string;
  LabelName1: string;
  MenuIcon: string;

}
export interface GroupALL {
  TicketID: string;
  Subject: string;  
}
export type Menu = {
  name: string, 
  submenu: { name: string}[]
}
