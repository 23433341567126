import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, NumberValueAccessor } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/Modules/user';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  myFormGroup:FormGroup;
  companies:any;
  CurLedgerID:any
  myControl = new FormControl();
  Comment:any
  datasource:any;
  CID:Number;
  TicketID:any=0;
  TemplateID:any=1;
  TemplateGroupID:any=1;
  UserID:any;

  config = {
    uiColor: '#ffffff',
    toolbarGroups: [
    { name: 'basicstyles', groups: ['basicstyles'] },
    { name: 'paragraph', groups: ['list', 'indent', 'align'] },
    { name: 'styles' },
    { name: 'colors' }
   ],
 removePlugins: 'elementspath,save,magicline',
 removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    
 }
 

  constructor(

    public service: HttpserviceService,
    public notificationService: NotificationService,
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<CommentComponent>,@Inject(MAT_DIALOG_DATA) 
    public data: any
  ) 
  {
    this.TicketID=this.data.TicketID;
    this.TemplateID=this.data.TemplateID;
    this.TemplateGroupID=this.data.TemplateGroupID;
  }

  ngOnInit() {
 
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserID=curUser.userid;

    this.loadData();
  }

  onSubmit()
  {
    
    let params = {
      cid:this.CID,
      flag:"COMMENT",
      TicketID:this.TicketID,
      TemplateID:this.TemplateID,
      UserName:this.CurLedgerID,
      LoginFlag:'',
      ActiviteID:'INT_Comment',
      TemplateGroupID:this.TemplateGroupID   ,
      Comment:this.Comment   
      }

      console.log('param',params);
      this.service.httpPost('/TicketCreator/SetTicketComment?',params)
      .subscribe(data => {
        let result: any;
        result=data;
        
        if (result.errno === 0){
          console.log('result',result);
          this.datasource=result.respdata.Comment  ;
          this.onCloseDialog();               
        }
        else{
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      }, 
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });

  }
  onCloseDialog()
  {
    // this.service.form.reset();
    // this.service.initializeFormGroup();
    this.dialogRef.close({event: 'CLEAR'});
  }
  onClear()
  {
    this.myFormGroup.reset(); 
    this.myFormGroup.disable();     
    this.myControl.reset();
  }

  loadData()
  {
      let params = {
      cid:this.CID,
      flag:"COMMENT",
      TicketID:this.TicketID,
      TemplateID:this.TemplateID,
      UserName:this.UserID,
      TagID:'',
      ActiviteID:'INT_Comment',
      TemplateGroupID:this.TemplateGroupID      
      }
      this.service.httpPost('/TicketCreator/GetTicketCreator?',params)
      .subscribe(data => {
        let result: any;
        result=data;
        
        if (result.errno === 0){
          console.log('result',result);
          this.datasource=result.respdata.Comment;
          
        }
        else{
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      }, 
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });
}
}
