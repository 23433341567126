import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe} from '@angular/common';
import * as _ from 'underscore/underscore-min.js';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthMasterListComponent } from './auth-master-list/auth-master-list.component';


@Component
({
  selector: 'app-auth-master',
  templateUrl: './auth-master.component.html',
  styleUrls: ['./auth-master.component.scss'],
})

// interface EmployeeGroup {
//   Level:number;
//   Name: string;
//   LedgerID: number;
// }

export class AuthMasterComponent implements OnInit 
{

  datasource: MatTableDataSource<GroupALL>;
  displayedColumns = ['AuthorizeGroupID', 'AuthorizeGroupName', 'Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  AuthMaster:FormGroup;
  Companies:any;
  cid:any;
  pageSize: number = 1;
  dataSource:any;
  localdt:any;
  CurLedgerID:any;


  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder,
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService)  
     {
         this.AuthMaster = this.formBuilder.group
         ({  
            fcCompany: ['', Validators.required] 
        }); 
     }


  ngOnInit() 
  {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.getddllist();  
    this.AuthMaster.controls.fcCompany.setValue(this.cid);
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }


  getddllist()
  {
    let params = 
    {
      CID: this.cid,
      strAuthID:0,
      Flag: "GETAUTHID",         
    };
    this.service.httpPost('/authmaster/GetAuthMaster',params)
      .subscribe (
        (data) => 
        { 
          let result: any;
          result = data;         
          console.log('data ', data);
          if (result.errno === 0) 
          {       
            this.Companies = result.respdata.Companyid;                          
            this.datasource=result.respdata.AuthDetails;
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.AuthDetails);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
          }
          else 
          {
            this.NotificationService.success(result.errdesc); 
          }
        },
        (error: AppError) => 
        {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }



  changesite(e)
  {
    console.log('change',e.value);
    this.cid=e.value;
    this.getddllist();
  }

  
  addRow()
  {
      if (this.cid != '')
     {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data =
       {
          flag: 'ADD',
          cid:this.cid
        };
        const dialogRef = this.dialog.open(AuthMasterListComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {     
          this.getddllist();     
          //console.log('HiADD')
        });
      }
  }


  onEdit(_row)
  {
    console.log("Rowavlue",_row.AuthorizeGroupID)
    // if (this.cid != '')
    // {
     const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data =
      {
        flag: 'EDIT',
        cid:this.cid,
        strAuthID:_row.AuthorizeGroupID,
        strAuthName:_row.AuthorizeGroupName
      };
      const dialogRef = this.dialog.open(AuthMasterListComponent, dialogConfig);    
      dialogRef.afterClosed().subscribe(result => {     
        this.getddllist();     
        //console.log('HiEdit')
      });

    //}
  }

  onView(_row)
  {
    console.log("Rowavlue",_row.AuthorizeGroupID)
    // if (this.cid != '')
    // {
     const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data =
      {
        flag: 'VIEW',
        cid:this.cid,
        strAuthID:_row.AuthorizeGroupID,
        strAuthName:_row.AuthorizeGroupName
      };
      const dialogRef = this.dialog.open(AuthMasterListComponent, dialogConfig);
      dialogRef.afterClosed();
  //  }
  }


  onDelete(_row)
  { 
    let params = 
    {     
      CID: this.AuthMaster.get('fcCompany').value,
      Flag: "DELETE",           
      strAuthID:_row.AuthorizeGroupID,
      strAuthName:_row.AuthorizeGroupName   , 
      intLevel:0,
      dt_GroupList: this.dataSource ,
      strUserID:this.CurLedgerID,
      strGroupID:sessionStorage.getItem('groupid')
    };
      this.service.httpPost('/authmaster/SetAuthMaster',params)
      .subscribe(
        (data) => 
      {
        let result: any;
        console.log('data ', data);
        result = data;       
        console.log('result ', result);
        if (result.errno === 0) 
        {
          this.NotificationService.success(result.errdesc);
          this.getddllist();
          console.log('HiDel')
        }
        else 
        {
          this.NotificationService.success(result.errdesc); 
          this.getddllist();
          console.log('HiDelete')
        }
      },
      (error: AppError) => 
      {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
}


export interface GroupALL 
{
  AuthorizeGroupID: string;
  AuthorizeGroupName: string;
 
}